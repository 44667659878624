export const ACCESS_TOKEN = "786yshgvf54";
export const ORG_ID = "8374mstsn23";
export const ALL_PERMISSIONS = "9873jjdbn2";
export const USER_PERMISSIONS = "9098klkjb32";

export const routesForBreadcrumbs = [
  {
    path: "/dashboard/app",
    breadcrumb: "Dashboard",
  },
  {
    path: "/dashboard/app/projects",
    breadcrumb: "All Projects",
  },
  {
    path: "/dashboard/app/projects/:id",
    breadcrumb: "Project Detail",
  },
  {
    path: "/dashboard/user",
    breadcrumb: "Users",
  },
  {
    path: "/dashboard/user/createUser",
    breadcrumb: "Create User",
  },

  {
    path: "/dashboard/profiles/:id",
    breadcrumb: "Personal Information",
  },
];

export const routesMapper = [
  "/dashboard/app",
  "/dashboard/app/projects",
  "/dashboard/app/projects/:id",
  "/dashboard/user/createUser",
  "/dashboard/user",
  "/dashboard/profiles",
  "/dashboard/profiles/:id",
];

export const ratingLabels = {
  0.5: "0.5",
  1: "1",
  1.5: "1.5",
  2: "2",
  2.5: "2.5",
  3: "3",
  3.5: "3.5",
  4: "4",
  4.5: "4.5",
  5: "5",
};

export const basicInfoPath = "basic";
export const addressesPath = "address";
export const bankDetailsPath = "bank-details";
export const verificationPath = "verification";
export const servicePath = "service";
export const portfolioPath = "portfolio";
export const uploadPortfolioPath = "upload-profile";
export const poc = "poc";
export const expertiseBreakup = "expertise-breakup";
export const uploadCOQPath = "upload-coq";
export const ra = "ra";
export const cvPath = "cv";
export const shortlist = "shortlist";
export const dealTimelimePath = "timeline";
export const saasChannelPartner = "saas-channel-partner";
export const certification = "certification";
export const testimonials = "testimonials";
export const services = "vendor-selection";
export const socialMediaRating = "social-media-rating";
export const founderInfo = "founderInfo";
export const techStackForte = "tech-stack-forte";
export const benchStrength = "bench-strength";
export const platformReview = "platform-review";

export const indianRegions = [
  { id: "andhra_pradesh", title: "Andhra Pradesh" },
  { id: "arunachal_pradesh", title: "Arunachal Pradesh" },
  { id: "assam", title: "Assam" },
  { id: "bihar", title: "Bihar" },
  { id: "chhattisgarh", title: "Chhattisgarh" },
  { id: "goa", title: "Goa" },
  { id: "gujarat", title: "Gujarat" },
  { id: "haryana", title: "Haryana" },
  { id: "himachal_pradesh", title: "Himachal Pradesh" },
  { id: "jharkhand", title: "Jharkhand" },
  { id: "karnataka", title: "Karnataka" },
  { id: "kerala", title: "Kerala" },
  { id: "madhya_pradesh", title: "Madhya Pradesh" },
  { id: "maharashtra", title: "Maharashtra" },
  { id: "manipur", title: "Manipur" },
  { id: "meghalaya", title: "Meghalaya" },
  { id: "mizoram", title: "Mizoram" },
  { id: "nagaland", title: "Nagaland" },
  { id: "odisha", title: "Odisha" },
  { id: "punjab", title: "Punjab" },
  { id: "rajasthan", title: "Rajasthan" },
  { id: "sikkim", title: "Sikkim" },
  { id: "tamil_nadu", title: "Tamil Nadu" },
  { id: "telangana", title: "Telangana" },
  { id: "tripura", title: "Tripura" },
  { id: "uttar_pradesh", title: "Uttar Pradesh" },
  { id: "uttarakhand", title: "Uttarakhand" },
  { id: "west_bengal", title: "West Bengal" },
  { id: "andaman_and_nicobar_islands", title: "Andaman and Nicobar Islands" },
  { id: "chandigarh", title: "Chandigarh" },
  {
    id: "dadra_and_nagar_haveli_and_daman_and_diu",
    title: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { id: "lakshadweep", title: "Lakshadweep" },
  { id: "delhi", title: "Delhi" },
  { id: "puducherry", title: "Puducherry" },
  { id: "ladakh", title: "Ladakh" },
  { id: "jammu_and_kashmir", title: "Jammu and Kashmir" },
];

export const indianRegionsSearch = [
  { id: "Andhra Pradesh", title: "Andhra Pradesh" },
  { id: "Arunachal Pradesh", title: "Arunachal Pradesh" },
  { id: "Assam", title: "Assam" },
  { id: "Bihar", title: "Bihar" },
  { id: "Chhattisgarh", title: "Chhattisgarh" },
  { id: "Goa", title: "Goa" },
  { id: "Gujarat", title: "Gujarat" },
  { id: "Haryana", title: "Haryana" },
  { id: "Himachal Pradesh", title: "Himachal Pradesh" },
  { id: "Jharkhand", title: "Jharkhand" },
  { id: "Karnataka", title: "Karnataka" },
  { id: "Kerala", title: "Kerala" },
  { id: "Madhya Pradesh", title: "Madhya Pradesh" },
  { id: "Maharashtra", title: "Maharashtra" },
  { id: "Manipur", title: "Manipur" },
  { id: "Meghalaya", title: "Meghalaya" },
  { id: "Mizoram", title: "Mizoram" },
  { id: "Nagaland", title: "Nagaland" },
  { id: "Odisha", title: "Odisha" },
  { id: "Punjab", title: "Punjab" },
  { id: "Rajasthan", title: "Rajasthan" },
  { id: "Sikkim", title: "Sikkim" },
  { id: "Tamil Nadu", title: "Tamil Nadu" },
  { id: "Telangana", title: "Telangana" },
  { id: "Tripura", title: "Tripura" },
  { id: "Uttar Pradesh", title: "Uttar Pradesh" },
  { id: "Uttarakhand", title: "Uttarakhand" },
  { id: "West Bengal", title: "West Bengal" },
  { id: "Andaman and Nicobar Islands", title: "Andaman and Nicobar Islands" },
  { id: "Chandigarh", title: "Chandigarh" },
  { id: "Dadra and Nagar Haveli and Daman and Diu", title: "Dadra and Nagar Haveli and Daman and Diu" },
  { id: "Lakshadweep", title: "Lakshadweep" },
  { id: "Delhi", title: "Delhi" },
  { id: "Puducherry", title: "Puducherry" },
  { id: "Ladakh", title: "Ladakh" },
  { id: "Jammu and Kashmir", title: "Jammu and Kashmir" },
];

