import Input from "./Input";
import { useStyles } from "./Form.theme";
import { useEffect, useMemo, useRef, useState } from "react";
import { getVirtualElement } from "../../utils/global/global";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { valuesIn, isEqual } from "lodash";

const Dropdown = ({
  label,
  options = [],
  inputProps,
  value,
  errorText,
  icons,
  disabled,
  resetTheField,
  refOptions = null,
  dropdownIcon = true,
  style
}) => {
  const classes = useStyles();
  const [showMenuItem, setShowMenuItem] = useState(false);
  const refElement = useMemo(() => {
    return getVirtualElement({ ...inputProps, type: "input" });
  }, []);

  // To Filter the Data in to dropdown option 
  const [filteredOptions, setFilteredOptions] = useState(options);
  const initialOptions = useRef(options);
  useEffect(() => {
    if (!isEqual(initialOptions.current, options)) {
      setFilteredOptions(options);
      // refElement.resetValue();
      if(label === "Select Sub Industry" && resetTheField){
         refElement.resetValue();
      }
    }
  }, [options]);

  // useEffect(()=>{
  //   if(label==="Tech Stack" && value===""){
  //     refElement.resetValue();
  //   }
  // },[value])

  function handleFilterData(e) {
    const value = e.target.value;
    if (!value) return setFilteredOptions(options);
    const filteredData = options.filter((item) => {
      if (item.label)
        return item.label.toLowerCase().includes(value?.toLowerCase());
      if (item.name)
        return item.name.toLowerCase().includes(value?.toLowerCase());
      if (item.title)
        return item.title.toLowerCase().includes(value?.toLowerCase());
    });
    setFilteredOptions(filteredData);
  }

  function handleItemClick(value) {
    refElement.setValue(value);
    setShowMenuItem(false);
  }

  var dropdownRef = useRef();

  function renderMenu() {
    return (
      <>
        {!disabled ? (
          <div className={classes.menuWrapper} ref={dropdownRef}>
            <ul className={classes.dropDown}>
              {filteredOptions.map((option) => {
                return (
                  <li
                    className={classes.dropdownItem}
                    onClick={() => handleItemClick(option.value || option._id || option.id)}
                  >
                    {option.label || option.title || option.name}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  useOnClickOutside(dropdownRef, () => setShowMenuItem(false));

  function getLabelFromValue() {
    const finsalOptions = refOptions ? refOptions : options;
    // console.log("value:", value);
    if (!value) return "--Select One--";
    const selectedOption = finsalOptions.find(
      (option) =>
        (option.value || option._id || option.id) === value ||
        (option.value || option._id || option.id) === value.id
    );
    // console.log("selectedOption", selectedOption);
    // if (!selectedOption) return "--Select One--";
    return (
      selectedOption?.label || selectedOption?.title || selectedOption?.name
    );
  }

  return (
    <div style={style} className={classes.dropdownWrapper}>
      {
        dropdownIcon && (
          <ArrowDropDownIcon
            sx={{
              position: "absolute",
              right: "6px",
              top: "45px",
            }}
          />
        ) || null
      }
      <Input
        style={{ cursor: "pointer" }}
        label={label}
        inputProps={{
          onClick: () => setShowMenuItem(true),
          readOnly: true,
        }}
        value={getLabelFromValue()}
        errorText={errorText}
        filterChange={handleFilterData}
      />
      {showMenuItem && renderMenu()}
    </div>
  );
};

export default Dropdown;
