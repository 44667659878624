import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { defaultAxios } from "../utils/axios/default.axios";
import { userAxios } from "../utils/axios/user.axios";
import storage from "redux-persist/lib/storage";
import { vendorAxios } from "../utils/axios/vendor.axios";
import { profileAxios } from "../utils/axios/profile.axios";
import { role_and_permission } from "../utils/axios/roles_and_permission.axios";
import { saAxios } from "../utils/axios/sa.axios";
import { logsAxios } from "../utils/axios/logs.axios";
import { clientAxios } from "../utils/axios/raClient.axiox";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => {
    let thunkWithParameters = thunk.withExtraArgument({
      apiService: {
        default: defaultAxios,
        user: userAxios,
        vendor: vendorAxios,
        profile: profileAxios,
        roles: role_and_permission,
        sa: saAxios,
        logs: logsAxios,
        client: clientAxios,
      },
    });
    return [thunkWithParameters];
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
