//Auth
export const emailLogin = "/user";
export const fetchAllForgotPasswordsApi = "/forgot_password";
export const validateTokenApi = "/user/validate_user_password_link";
export const setPasswordApi = "/reset_password";

//Users
export const userApi = "/user";
export const uploadUserProfileApi = "/user/upload_profile";

//update password
export const updatePasswordsApi = "/user/change_password";

//Onboarding - services
export const getServiceListApi = "/level-zero/service-listing";
export const postServicesApi = "vendor/level-zero/service";
export const getSelectedServicesList = "vendor/level-zero/service";

//Profile Details
export const getVendorProfileDetails = "/vendor/business_profile";
export const getVendorReamimingApprovalFeilds = "/supply/business-profile/approval-fields";
export const fetchAllVendorProfilesApi = "/vendor/get_all_business_profiles";
export const fetchAllVendorProfilesApiNew =
  "/vendor/get_all_business_profiles/new";
export const searchBusinessProfileApi = "/vendor/filter_bus_profile";
export const getNotesByID = "vendor/business_profile/get_notes";
export const getVenderExist = "check_vendor";
export const addNotesByID = "vendor/business_profile/notes";
export const updateNotesByID = "vendor/business_profile/notes";
export const businessAddressApi = "/vendor/business_addresses/new";
export const addBusinessAddress = "/vendor/business_address/new";
export const searchLanguageApi = "/search/languages";
export const searchSkillApi = "/search/skills";
export const updateLanguagesApi = "/vendor/languages";
export const updateSkillsApi = "/vendor/skills";
export const getOrgIdByProfileIdApi = "/get_profile_org";
export const deleteNoteApi = "/vendor/business_profile/notes";
// export const advanceFilterApi = "/vendor/filter_bus_profile";
export const advanceFilterApi = "vendor/filter_new_vendors_profile"

//vendor sass api url
export const getVendorSassDetailsApi =
  "/vendor/business-profile/get-saas-partnership";
export const deleteVendorSassProfile =
  "/vendor/business-profile/saas-partnership";
export const createVendorSassProfile =
  "/vendor/business-profile/saas-partnership";
//founder profile info
export const getFounderProfileApi = "/vendor/business-profile/get-founders";
export const createFounderProfileApi = "/vendor/business-profile/founder";
export const deleteFounderProfileApi = "/vendor/business-profile/founder";

//bench strength info
export const getBenchStrengthInfoApi =
  "/vendor/business-profile/bench-strengths";
export const createBenchStrengthInfoApi =
  "/vendor/business-profile/bench-strength";
export const deleteBenchStrengthInfoApi =
  "/vendor/business-profile/bench-strength";

//testimonial info api
export const getTestimonialInfoApi =
  "/vendor/business-profile/get-testimonials";
export const deleteTestimonialInfoApi = "/vendor/business-profile/testimonial";
export const createTestimonialInfoApi = "/vendor/business-profile/testimonial";

//certificate info api
export const getCertificateInfoApi =
  "/vendor/business-profile/get-certifications";
export const createCertificateInfoApi =
  "/vendor/business-profile/certification";
export const deleteCertificateInfoApi =
  "/vendor/business-profile/certification";

//platform review info api
export const getPlatformReviewApi = "/vendor/business-profile/platform-review";
export const createPlatformReviewApi =
  "/vendor/business-profile/platform-review";
export const deletePlatformReviewApi =
  "/vendor/business-profile/platform-review";

//tech stack forte info api
export const getTechStackForteApi = "/vendor/business-profile/get-tech-stacks";
export const createTechStackForteApi = "/vendor/business-profile/tech-stack";
export const deleteTechStackForteApi = "/vendor/business-profile/tech-stack";

//expertise breakup info api
export const getExpertiseBreakupApi = "/vendor/expertise/new";
export const createExpertiseBreakupApi = "/vendor/expertise/new";
export const deleteExpertiseBreakupApi = "/vendor/expertise/info/new";

//Services
export const getAllServices = "/services";
export const deleteServices = "/service";
export const addServices = "/service";
export const userAddress = "/client/business_address";
export const getServiceTeckStacksApi = "/get_service_tech_stacks";
export const getServiceTagsApi = "/get_service_tags";
export const fetchTeckStackBySearchApi = "/search/tech_stacks";
export const fetchTagsBySearchApi = "/search/tags";
export const fetchVendorShortlistedProfilesByFilterApi =
  "/supply/filter_vendors_profile";

// Notifications
export const fetchAllNotificationsApi = "/get_all_user_notifications/";
export const markNotificationAsReadApi = "/mark_push_notification_as_read";
export const markAllNotificationsAsReadApi =
  "/mark_all_push_notification_as_read";

// Suppport
export const reportedIssueApi = "/support";
export const fetchAllSupportApi = "/exmyb/support/get_general_tickets";
export const allVendorServices = "/get_vendor_services";
export const vendorBusinessAddress = "/vendor/business_addresses";
export const vendorNotes = "/vendor/business_profile/get_notes";
export const getAllSupportComment = "/support/comments";
export const createGlobalSupportCommentApi = "/support/comment";
export const ticketstatusApi = "/change_support_ticket/state";
// export const viewVendorNotes = "/vendor/business_profile/get_notes";

// Vendor Business Profile
export const businessProfileApi = "/vendor/business_profile";

// industries
export const allFunctionIndustry = "/industry";
export const getAllIndustry = "/industries";
export const deleteIndustry = "/industry";
export const addIndustry = "/vendor_industry/(vendor_id)/(profile_id)";
export const globalReportedIssueApi = "/support_ticket";
export const searchIndustryApi = "/industries/search_industry";
export const industryValidateSlug = "/industry_slug";

// vendor service

export const vendorService = "/vendor_service";
export const validateSlugServiceApi = "/service_slug";

// portfolio
export const getAllPortfolio = "/vendor/get_profile_portfolios";
export const deletePortfolio = "/vendor/portfolio";
export const addPortfolio = "/vendor/portfolio";
export const vendorViewPortFolio = "/vendor/get_profile_portfolios";

//past work info
export const getAllPastWorkApi = "/vendor/get-pastworks";
export const pastworkApi = "/vendor/portfolio/new";
export const getTechDataApi =
  "/vendor/past-work/resource-bifurcation/tech-stacks";
// export const deletePastwork = "/vendor/portfolio/new";
// export const createPastwork = "/vendor/portfolio/new";

// upload portfolio

export const getAllUploadPortfolio = "/vendor/portfolio_files";
export const deleteUploadPortfolio = "/portfolio";
export const addUploadPortfolio = "/vendor/portfolio_files";

// vendor services

export const getAllVendorServices = "/get_vendor_services";
export const searchVendorServiceApi = "/get_vendor_services";
export const deleteVendorServices = "/vendor_service";
export const addVendorServices = "/vendor_service";
export const searchServiceApi = "/service/search_service";
export const addCustomServiceApi = "/vendor_service";

//product code api
export const searchProductCodeIndustryApi = "/industries/search_industry";
export const searchProductCodeSubIndustryApi = "/sub-industry";
export const searchProductCodeDeliveryClassApi = "/vendor/get-delivery-class";
export const getAllProductCodeListApi = "/vendor/get-product-codes";
export const productCodeApi = "/vendor/product-code";

//bench strength
export const searchBenchStrengthLocationApi =
  "/vendor/business-profile/india-cities";
export const searchBenchResourceBifurcationApi =
  "/vendor/bench-strength/resource-bifurcations";
// skills
export const getAllSkills = "/get_all_skills";
export const deleteSkills = "/skill";
export const updateSkills = "/skill";
export const addSkills = "/skill";

// language
export const getAllLanguage = "/get_all_languages";
export const deleteLanguage = "/language";
export const updateLanguage = "/language";
export const addLanguage = "/language";

// Dashboard
export const dashboardMatrxiApi = "/ops_dashboard_metrics";

//POC
export const addPOCApi = "/vendor/contact/new";
export const fetchAllPOCApi = "/vendor/contacts";
export const updatePOCApi = "/vendor/contact/new";
export const deletePOCApi = "/vendor/contact";

// tech stack
export const getAllTechstack = "/get_all_tech_stacks";
export const addTechstack = "/tech_stack";
export const deleteTechstack = "/tech_stack";
export const updateTechstack = "/tech_stack";
export const getAllTechstackAndTagsById = "/service/get_tech_stacks_tags";

// expertise_breakup
export const getAllExpertise = "/vendor/all_expertise";
export const deleteExpertise = "/vendor/expertise";
export const updateExpertise = "/vendor/expertise";
export const addExpertise = "/vendor/expertise";

// upload coq

export const getAllUploadCOQ = "/vendor/get_coq_files";
export const getCOQfilesByID = "/vendor/coq_files/1";
export const deleteUploadCOQ = "vendor/coq_files/3";
export const addUploadCOQ = "/vendor/coq_files";

export const updateDeleteAddExpertise = "/vendor/expertise";
// team_info
export const getAllTeamInfo = "/vendor/get_all_role_wise_teams";
export const updateDeleteAddTeamInfo = "/vendor/role_wise_team";

// Turnover
export const fetchTurnoverForLast3YearsApi = "/vendor/last_three_turnovers";
export const turnoverApi = "/vendor/turnover/new";

// tag
export const getAllTags = "/get_tags";
export const addTag = "/tag";
export const deleteTag = "/tag";
export const updateTag = "/tag";

// Accounts
export const getAssignedVendorApi = "/vendor/business_profile/assign_vendor";

// shortlisting
export const getAllShortlistedVendors = "/supply/get_deals";
export const getShortlistedVendorById = "/supply/get_deal";
export const searchShortlistedVendors = "/supply/search_deals";
export const getRecommendedVendors = "/supply/get_recommended_vendors";
export const getShortlistedVendors = "supply/shortlisted_vendors";
export const addVendorAsSelected = "supply/shortlisted_vendors";
export const deleteVendorAsSelected = "supply/shortlisted_vendors";
export const getVendorByPortfolioIds =
  "vendor/get_profile_portfolios_by_ids?portfolio_ids";

export const selectVerifiedVendorApi =
  "/vendor/change_business_profile_stage/new";

// Category
export const getAllCategories = "/get_all_categories";
export const addCategory = "/category";
export const deleteCategory = "/category";
export const updateCategory = "/category";

// RA
// export const fetchAllRA = "/ra/leads";
export const fetchAllRA = "/ra/leads/new";
export const fetchAllSupplyUser = "/ra/supply/users/";
export const postAssignUser = "/ra/supply/user/tag";
export const searchClientRequirment = "/ra/leads/new";
export const raEnableVendors = "/vendor/ra-enable/profile";
export const fetchRAById = "/ra/lead";
export const fetchRAByLineItems = "/checkUserAccessForSADeal";
// export const fetchAllCvsByPartner = "/ra/emb/line_items";
export const fetchAllCvsByPartner = "/ra/emb/line_items/new";
// export const changeStatusOfLead = "/ra/emb/line_item/change_status";
export const changeStatusOfLead = "/ra/emb/line_item/change_status/new";
// export const uploadCVByEMB = "/ra/emb/line_item/upload_cv";
export const uploadCVByEMB = "/ra/emb/line_item/upload_cv/new";
export const fetchAllTechStackList = "/all/tech-stacks";
// export const postShortlistApi = "/upload/emb-jd";
export const putShortlistApi = "/supply/line-item/partner-shortlist";
export const putEmbJdUpload = "/supply/line-item/emb-jd";
// finance approval
export const getAllVenderForFinance = "/get_vendors_for_account_approval";
export const getAllVenderForFinanceNew =
  "/get_vendors_for_account_approval/new";
export const approveFinance = "/mark_vendor_as_verified_accounts_team";
export const rejectFinance = "/mark_vendor_as_unapprvoed_accounts_team";

// resource bifurcation
export const getResourceBifurcationListApi =
  "/vendor/bench-strength/resource-bifurcations";
export const getTechStackListApi = "/vendor/bench-strength/tech-stacks";
export const fetchAllProductCodeListApi = "/vendor/product-codes";

// new services and industryApis
// export const fetchAllServicesApi = "/service/search_service/new";
export const fetchAllServicesApi = "/level-one/service-listing";
export const fetchAllIndustriesApi = "/industries/search_industry/new";
export const fetchAllDeliveryClassApi = "/vendor/get-delivery-class";
export const fetchSubIndustryApi = "/sub-industry";
export const fetchAllSubIndustryApi = "/sub-industries";

export const fetchResourceListApi =
  "/vendor/past-work/resource-bifurcation/tech-stacks";

export const fetchAllSystemPermissionsApi = "/permissions";
export const fetchAllUserPermissionsApi = "/userPermissions";

// User and roles for RA
export const fetchAllSALeadsApi = "/fetchSALeads";
export const fetchAllSAUsersApis = "/getAllSAUsers";

// Apis for Logs
export const assignUserSALogAPI = "/emb/request";


// RA NewFlow Apis For Assigment 
export const uploadAssignmentByEMB = "/client/internal/requirement/";
export const hireTelentByEMB = "client/ra/applicant-status/";
export const uploadAssignmentFile = '/api/v1/upload/doc';
export const cliedtData = '/client/ra/clint-detail/';
export const assesmenUploadFile = '/lead/assigment/submit'
export const assesmenUpload = 'ra/lead/assignment-submit'
