import {
  Box,
  Button,
  Typography,
  Link,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useStyles as formStyles } from ".././../components/Form/Form.theme";
import { useStyles } from "./RaCvTable";
import Element from "../../components/Form/Element";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import {
  fetchAllCvsByPartnerThunk,
  uploadAssignmentByEMBThunk,
  uploadFileEmbByEMBThunk,
} from "../../store/slices/ra/ra.slice";
import { useParams } from "react-router-dom";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AssignAssignment = ({ applicantId, onClose }) => {
  const classes = useStyles();
  const formClasses = formStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const [assignmentData, setAssignmentData] = useState({
    assignment_name: "",
    candidate_name: applicantId?.applicant_name || "",
    assignment_submission_date: new Date().toISOString().split("T")[0],
    attachments: [],
  });

  // Handler for assignment name
  const handleAssignmentNameChange = (e) => {
    setAssignmentData((prev) => ({
      ...prev,
      assignment_name: e.target.value,
    }));
  };

  // Handler for candidate name
  const handleCandidateNameChange = (e) => {
    setAssignmentData((prev) => ({
      ...prev,
      candidate_name: e.target.value,
    }));
  };

  // Handler for submission date
  const handleSubmissionDateChange = (e) => {
    setAssignmentData((prev) => ({
      ...prev,
      assignment_submission_date: e.target.value,
    }));
  };

  // Handler for file upload
  const handleFileUpload = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        console.log("No file selected");
        return;
      }

      const file = e.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_type", "assessment_submit");

      const response = await dispatch(
        uploadFileEmbByEMBThunk({
          formData: formData,
          applicant_id: applicantId?.id,
        })
      );
      if (response.type === "uploadFileByEMB/fulfilled") {
        setAssignmentData((prev) => ({
          ...prev,
          attachments: [...prev.attachments, response.payload?.url],
        }));
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  // Handler to remove attachment
  const handleRemoveAttachment = (index) => {
    setAssignmentData((prev) => {
      const updatedAttachments = [...prev.attachments];
      // Revoke the object URL to avoid memory leaks
      if (updatedAttachments[index]?.url?.startsWith("blob:")) {
        URL.revokeObjectURL(updatedAttachments[index].url);
      }
      updatedAttachments.splice(index, 1);
      return {
        ...prev,
        attachments: updatedAttachments,
      };
    });
  };

  const handleSubmitAssignment = () => {
    const payload = {
      id: applicantId?.id,
      requirement_id: applicantId?.requirement_id,
      data: {
        assignment_name: assignmentData.assignment_name,
        candidate_name: assignmentData.candidate_name,
        assignment_submission_date: assignmentData.assignment_submission_date,
        attachments: assignmentData.attachments,
      },
    };
    dispatch(uploadAssignmentByEMBThunk(payload))
      .unwrap()
      .then(() => {
        if (onClose) onClose();
        dispatch(fetchAllCvsByPartnerThunk(params.leadId));
      })
      .catch((error) => {
        console.error("Error submitting assignment:", error);
      });
  };

  return (
    <Box sx={styleModal}>
      <Typography
        variant="h5"
        sx={{
          color: "#4d4d4d",
          fontWeight: "600",
          paddingBottom: "10px",
        }}
      >
        Provide Assignment
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
        className={classes.dropdownIconCss}
      >
        <Box>
          <Element
            label={"Assignment Name"}
            eletype="input"
            value={assignmentData.assignment_name}
            inputProps={{
              type: "text",
              onChange: handleAssignmentNameChange,
            }}
            fullWidth
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Element
              disabled={true}
              label={"Candidate Name"}
              eletype="input"
              value={assignmentData.candidate_name}
              inputProps={{
                type: "text",
                onChange: handleCandidateNameChange,
              }}
              fullWidth
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <Element
              label={"Assignment Submission Date"}
              eletype="input"
              value={assignmentData.assignment_submission_date}
              inputProps={{
                type: "date",
                onChange: handleSubmissionDateChange,
              }}
              fullWidth
            />
          </Box>
        </Box>

        <Box>
          <div className="file-upload">
            <div className="file-select">
              <div className="file-select-button" id="fileName">
                Upload
              </div>
              <div className="file-select-name" id="noFile">
                Browse File...
              </div>
              <input
                type="file"
                name="chooseFile"
                className={formClasses.formControl}
                onChange={handleFileUpload}
              />
            </div>
          </div>
        </Box>

        {assignmentData.attachments.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mb: 2 }} variant="subtitle1">
              Assigment:
            </Typography>
            <List sx={{ mt: 2 }}>
              {assignmentData.attachments.map((attachment, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => handleRemoveAttachment(index)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <Link
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {attachment.name || `Attachment ${index + 1}`}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{ marginLeft: "5px", mt: 2 }}
          onClick={handleSubmitAssignment}
          disabled={assignmentData.attachments.length === 0}
        >
          Submit Assignment
        </Button>
      </Box>
    </Box>
  );
};

export default AssignAssignment;
