import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import "../Profile/upload.css";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

// components
import {
  createBusinessProfileThunk,
  fetchVendorProfileDetailsThunk,
  fetchVendorReamimingApprovalFeildsThunk,
  updateBusinessProfileThunk,
} from "../store/slices/vendor/vendor.slice";
import {
  fetchNotesByIdThunk,
  deleteNotesThunk,
} from "../store/slices/notes/notes.slice";
import Element from "../components/Form/Element";
import { inputType, vendorVerifiedEnum } from "../utils/enum";
import { basicInfoValidations } from "../utils/validations/vendor.validation";
import {
  augmentationOptions,
  cmmiLevelOptions,
  companyTurnoverOptions,
  noOfClientsOptions,
  numberOfEmployeesOptions,
  typeOfCompanyOptions,
} from "../utils/options.util";
import MobileInput from "../components/Form/MobileInput";
import useForm from "../hooks/useForm";
import useBusinessId from "../hooks/useBusinessId";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Turnover from "./Trunover";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { selectVerifiedVendor } from "../utils/global/vendor.global";
import useUser from "../hooks/useUser";
import {
  showFailureToast,
  showSuccessToast,
} from "../store/slices/toast/toast.slice";
import usePermissions from "../hooks/usePermission";
import systemPermissions, { actions } from "../utils/global/permissions.global";
import { capitalCase } from "change-case";

export const useStyles = makeStyles({
  dropdownIconCss: {
    "& svg": {
      bottom: "24px !important",
      top: "14px !important",
    },
    "& ul": {
      marginTop: "-32px",
    },
  },
  "@media (max-width:600px)": {
    dropdownIconCss: {
      padding: "0px  !important",
    },
  },
});

const pendingDropdownValues = [
  {
    label: "Pending",
    value: vendorVerifiedEnum.pending,
  },
  {
    label: "Approved",
    value: vendorVerifiedEnum.approved,
  },
];

const approvalDropdownValues = [
  {
    label: "Approved",
    value: vendorVerifiedEnum.approved,
  },
  {
    label: "Verified",
    value: vendorVerifiedEnum.verified,
  },
  {
    label: "Unverified",
    value: vendorVerifiedEnum.unverified,
  },
];

const verifiedDropdownValues = [
  {
    label: "Verified",
    value: vendorVerifiedEnum.verified,
  },
  {
    label: "Unverified",
    value: vendorVerifiedEnum.unverified,
  },
];

const unverifiedDropdownValues = [
  {
    label: "Unverified",
    value: vendorVerifiedEnum.unverified,
  },
  {
    label: "Approved",
    value: vendorVerifiedEnum.approved,
  },
  {
    label: "Sent For Accounts Approval",
    value: vendorVerifiedEnum.sent_for_accounts_approval,
  },
];

export default function BasicInfo() {
  const [infoSelect, setInfoSelect] = useState(pendingDropdownValues);
  const [prevVendorState, setPrevVendorState] = useState(
    vendorVerifiedEnum.pending
  );
  const [vendorState, setVendorState] = useState(vendorVerifiedEnum.pending);
  const { profileData, reaminingApprovalsField } = useSelector(
    ({ vendor }) => vendor
  );
  const dispatch = useDispatch();
  const { notesData, totalNotes } = useSelector(({ notes }) => notes);
  const [note, setNote] = useState(null);
  const [editID, setEditId] = useState("");
  const navigate = useNavigate();
  const classes = useStyles();
  const user = useUser();

  const userPerm = usePermissions();

  const [augmentation_service, setAugmentationService] = useState(
    profileData?.resource_augmentation_service ? "on" : "off"
  );

  useEffect(() => {
    setPrevVendorState(profileData.new_profile_stage);
    setVendorState(profileData.new_profile_stage);
    if (profileData.new_profile_stage === vendorVerifiedEnum.pending) {
      setInfoSelect(pendingDropdownValues);
    } else if (
      profileData.new_profile_stage === vendorVerifiedEnum.accounts_approved
    ) {
      setInfoSelect(verifiedDropdownValues);
      setVendorState(vendorVerifiedEnum.verified);
    } else if (profileData.new_profile_stage === vendorVerifiedEnum.verified) {
      setInfoSelect(verifiedDropdownValues);
    } else {
      setInfoSelect(unverifiedDropdownValues);
    }
  }, [profileData.new_profile_stage]);

  useEffect(() => {
    if (page !== 0) {
      dispatch(fetchNotesByIdThunk(page));
      setPage(parseInt(page - 1));
    }
  }, [notesData.length == 0]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const handleChangePage = (_, newPage) => {
    dispatch(fetchNotesByIdThunk(newPage + 1));
    setPage(newPage);
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        brand_name: profileData.brand_name,
        registered_business_name: profileData.registered_business_name,
        company_type: profileData.company_type,
        mobile: {
          country_code: profileData.country_code || "",
          mobile_number: profileData.mobile_number || "",
        },
        year_of_establishment: profileData.year_of_establishment
          ? new Date(profileData.year_of_establishment)
          : "",
        turnover: profileData.turnover,
        number_of_employees: profileData.number_of_employees,
        number_of_clients: profileData.number_of_clients,
        website: profileData.website,
        logo: profileData.logo ? profileData.logo : {},
        // rating: profileData.rating,
        description: profileData.description,
        // verified: profileData.verified ? "on" : "off",
        activeProjectNumber: profileData?.active_project_number,
        augmentationModeSelect: profileData?.augmentation_mode_select,
        cmmiLevelSelect: profileData?.cmmi_level_select,
        resourceAugmentationService: profileData?.resource_augmentation_service
          ? "on"
          : "off",
      },
      onSubmit: onSubmit,
      validationSchema: basicInfoValidations,
    });
  const businessId = useBusinessId();

  useEffect(() => {
    dispatch(fetchNotesByIdThunk());
    dispatch(fetchVendorReamimingApprovalFeildsThunk());
  }, [dispatch, businessId]);

  function onSubmit(data) {
    if (!businessId) {
      return dispatch(
        createBusinessProfileThunk({
          data: {
            ...data,
            ...data.mobile,
            // verified: data.verified === "off" ? false : true,
            resourceAugmentationService:
              data.resourceAugmentationService === "off" ? false : true,
            year_of_establishment: new Date(
              data.year_of_establishment
            ).getFullYear(),
            activeProjectNumber: Number(data.activeProjectNumber),
            registered_business_name: data.brand_name,
          },
        })
      )
        .unwrap()
        .then((response) => {
          navigate(`/dashboard/profiles/${response?.id}/basic`);
        });
    }
    dispatch(
      updateBusinessProfileThunk({
        id: businessId,
        data: {
          ...data,
          ...data.mobile,
          // verified: data.verified === "off" ? false : true,
          resourceAugmentationService:
            data.resourceAugmentationService === "off" ? false : true,
          year_of_establishment: new Date(
            data.year_of_establishment
          ).getFullYear(),
          activeProjectNumber: Number(data.activeProjectNumber),
          registered_business_name: data.brand_name,
        },
      })
    )
      .unwrap()
      .then(dispatch(fetchVendorProfileDetailsThunk()));
  }

  function handleNotesChange(e) {
    setNote(e.target.value);
  }

  function handleEdit(id, data) {
    setEditId(id);
    setNote(data);
  }

  function fncCancelNotes() {
    setEditId("");
    setNote("");
  }

  function handleNotesDelete(id) {
    setEditId("");
    setNote("");
    dispatch(deleteNotesThunk({ id: id, page: page === 0 ? 1 : page + 1 }));
  }
  function handleVendorStateChange(e) {
    setVendorState(e.target.value);
  }

  function selectValChange() {
    if (vendorState === prevVendorState) return;

    selectVerifiedVendor({
      stage: vendorState,
    })
      .then((response) => {
        setPrevVendorState(vendorState);
        if (vendorState === vendorVerifiedEnum.approved) {
          setInfoSelect(approvalDropdownValues);
        } else if (vendorState === vendorVerifiedEnum.verified) {
          setInfoSelect(verifiedDropdownValues);
        } else {
          setInfoSelect(unverifiedDropdownValues);
        }
        dispatch(
          showSuccessToast({
            message: response.message,
            horizontal: "right",
            vertical: "top",
          })
        );
        dispatch(fetchVendorReamimingApprovalFeildsThunk());
      })
      .catch((err) => {
        dispatch(
          showFailureToast({
            message: err,
            horizontal: "right",
            vertical: "top",
          })
        );
      });
  }

  function formatText(str) {
    if(!str) return "";
    return str
      .split("_") // Split by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join with space
  }

  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={5}>
          <Grid item md={9}>
            <Card sx={{ minHeight: "420px", py: 2, px: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                m={2}
              >
                <Typography variant="h4" gutterBottom>
                  Basic Info
                </Typography>
                {profileData.new_profile_stage ===
                "sent_for_accounts_approval" ? (
                  <Typography color={"green"} variant="h6" gutterBottom>
                    Sent For Finance Approval
                  </Typography>
                ) : profileData.new_profile_stage === "accounts_approved" ? (
                  <Typography color={"green"} variant="h6" gutterBottom>
                    Verified
                  </Typography>
                ) : profileData.new_profile_stage ===
                  "unapproved_by_accounts" ? (
                  <Typography color={"green"} variant="h6" gutterBottom>
                    Rejected by Finance
                  </Typography>
                ) : (
                  userPerm.hasAccess(
                    systemPermissions["vendorProfileDetailPage.canApprove"],
                    actions.create
                  ) &&
                  user.isBusinessProfileStageChangeAllowed && (
                    <Box
                      sx={{ display: "flex" }}
                      className={classes.dropdownIconCss}
                    >
                      <Element
                        label=""
                        value={vendorState}
                        options={infoSelect}
                        eletype={inputType.select}
                        inputProps={{
                          type: "text",
                          onChange: handleVendorStateChange,
                          onBlur: handleBlur,
                        }}
                        icons={
                          <ArrowDropDownIcon
                            sx={{ position: "absolute", right: 20, bottom: 24 }}
                          />
                        }
                      />

                      <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{ marginLeft: "5px" }}
                        onClick={selectValChange}
                      >
                        Save
                      </Button>
                    </Box>
                  )
                )}
              </Stack>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} p={3}>
                  <Grid item md={4} pb={3}>
                    <Element
                      label="Brand Name"
                      inputProps={{
                        placeholder: "Enter Brand Name",
                        type: "text",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "brand_name",
                      }}
                      eletype={inputType.input}
                      value={values.brand_name}
                      errorText={touched.brand_name && errors.brand_name}
                      disabled={
                        profileData.new_profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  {/* <Grid item md={4} p={3}>
                    <Element
                      label="Brand Name"
                      inputProps={{
                        placeholder: "Enter Brand Name",
                        type: "text",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "registered_business_name",
                      }}
                      eletype={inputType.input}
                      value={values.registered_business_name}
                      errorText={
                        touched.registered_business_name &&
                        errors.registered_business_name
                      }
                      disabled={
                        profileData.new_profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid> */}
                  <Grid item md={4} p={3}>
                    <Element
                      disabled={
                        profileData.new_profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                      label="Type of Company"
                      inputProps={{
                        name: "company_type",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      value={values.company_type}
                      options={typeOfCompanyOptions}
                      errorText={touched.company_type && errors.company_type}
                      eletype={inputType.select}
                    />

                    {/* <ArrowDropDownIcon /> */}
                  </Grid>
                  <Grid item md={4} p={3}>
                    <MobileInput
                      name="mobile"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile}
                      errorText={touched.mobile && errors.mobile}
                      label="Enter Mobile"
                      disabled={
                        profileData.new_profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item md={4} p={3}>
                    <Element
                      inputProps={{
                        name: "year_of_establishment",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        placeholder: "Select Year",
                      }}
                      value={values.year_of_establishment}
                      eletype={inputType.date}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={9}
                      label="Year of Establishment"
                      errorText={
                        touched.year_of_establishment &&
                        errors.year_of_establishment
                      }
                    />
                  </Grid>
                  <Grid item md={4} p={3}>
                    <Element
                      label="Company Turnover(Last Year)"
                      inputProps={{
                        name: "turnover",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      value={values.turnover}
                      options={companyTurnoverOptions}
                      errorText={touched.turnover && errors.turnover}
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={4} p={3}>
                    <Element
                      label="Number of Employees"
                      inputProps={{
                        name: "number_of_employees",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      value={values.number_of_employees}
                      options={numberOfEmployeesOptions}
                      errorText={
                        touched.number_of_employees &&
                        errors.number_of_employees
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={4} p={3}>
                    <Element
                      label="Number of Clients"
                      inputProps={{
                        name: "number_of_clients",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      value={values.number_of_clients}
                      options={noOfClientsOptions}
                      errorText={
                        touched.number_of_clients && errors.number_of_clients
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={4} p={3}>
                    <Element
                      eletype={inputType.input}
                      inputProps={{
                        type: "text",
                        name: "website",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        placeholder: "Website Name",
                      }}
                      value={values.website}
                      errorText={touched.website && errors.website}
                      label="Website"
                    />
                  </Grid>
                  <Grid item md={4} p={3}>
                    <Element
                      eletype={inputType.file}
                      label="Business Logo (png/jgp/jpeg/avif)"
                      inputProps={{
                        name: "logo",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      url="/vendor/upload_business_logo"
                      extraArguments={{
                        upload_type: "business_logo",
                      }}
                      value={values.logo}
                      errorText={touched.logo && errors.logo}
                    />
                  </Grid>
                  <Grid item md={4} p={3}>
                    <Element
                      label="Number of Active Projects"
                      inputProps={{
                        placeholder: "Enter Active Project Number",
                        type: "number",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "activeProjectNumber",
                      }}
                      eletype={inputType.number}
                      value={values.activeProjectNumber}
                      errorText={
                        touched.activeProjectNumber &&
                        errors.activeProjectNumber
                      }
                    />
                  </Grid>
                  <Grid item md={4} p={3}>
                    <Element
                      label="CMMI Level"
                      inputProps={{
                        name: "cmmiLevelSelect",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      value={values.cmmiLevelSelect}
                      errorText={
                        touched.cmmiLevelSelect && errors.cmmiLevelSelect
                      }
                      options={cmmiLevelOptions}
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={12} p={3}>
                    <Element
                      eletype={inputType.textarea}
                      label="Description"
                      inputProps={{
                        name: "description",
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                      value={values.description}
                      errorText={touched.description && errors.description}
                      cols="4"
                      rows="6"
                    />
                  </Grid>
                  <Grid item md={12} sx={{ marginTop: "23px" }}>
                    <Element
                      eletype={inputType.switch}
                      label="Are you providing Resource Augmentation services?"
                      inputProps={{
                        name: "resourceAugmentationService",
                        onChange: (e) => {
                          setAugmentationService(e.target.value);
                          handleChange(e);
                        },
                        handleBlur: handleBlur,
                      }}
                      value={values.resourceAugmentationService}
                    />
                  </Grid>
                  {augmentation_service === "on" ? (
                    <>
                      <Grid item md={12} p={3}>
                        <Element
                          label="Resource Augmentation Mode"
                          // inputProps={{
                          //   name: "augmentationModeSelect",
                          //   onChange: handleChange,
                          //   onBlur: handleBlur,
                          // }}
                          name="augmentationModeSelect"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.augmentationModeSelect}
                          options={augmentationOptions}
                          eletype={inputType.checkboxgroup}
                          icons={
                            <ArrowDropDownIcon
                              sx={{
                                position: "absolute",
                                right: 20,
                                bottom: 24,
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item md={12} p={3}>
                        <span style={{ fontWeight: "bold" }}>Note: </span>
                        If you are providing both onsite and remote then check
                        both the boxes.
                      </Grid>
                    </>
                  ) : null}
                  {userPerm.hasAccess(
                    systemPermissions["vendorProfileDetailPage.info"],
                    actions.create
                  ) && (
                    <Grid item md={12} p={3}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ float: "right" }}
                      >
                        Save
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            </Card>
            {/* <BasicInfoLoader /> */}
            <Turnover />
            {/* <Box mt={5}>
              <Card sx={{ padding: "2rem" }}>
                <Typography mb={4} variant="h4">
                  Notes{" "}
                </Typography>
                {notesData.length == 0 ? (
                  ""
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Message</TableCell>
                          <TableCell align="center">Added By</TableCell>
                          <TableCell align="center">Timestamp</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {notesData?.map((note) => (
                          <TableRow
                            key={note?.notes}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {note?.notes_content}
                            </TableCell>
                            <TableCell align="center">
                              {note?.created_by}
                            </TableCell>
                            <TableCell align="center">
                              {DateTime.fromISO(note?.created_at).toFormat(
                                "DD"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton color="success" component="span">
                                <EditIcon
                                  color="success"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleEdit(note?.id, note?.notes_content)
                                  }
                                />
                              </IconButton>
                              <DeleteAction
                                handleDelete={() => handleNotesDelete(note?.id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            count={totalNotes}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={false}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                )}
                <Box mt={5} ml={2}>
                  <form
                    onSubmit={(e) => {
                      if (editID == "") {
                        e.preventDefault();
                        if (!note) return;
                        dispatch(
                          addNotesByIdThunk({
                            id: businessId,
                            name: note,
                            page: page == 0 ? 1 : page + 1,
                          })
                        )
                          .unwrap()
                          .then((d) => {
                            setNote("");
                          });
                      } else {
                        e.preventDefault();
                        if (!note) return;
                        dispatch(
                          updateNotesThunk({
                            id: editID,
                            notes: note,
                          })
                        )
                          .unwrap()
                          .then((d) => {
                            setNote("");
                            setEditId("");
                          });
                      }
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <TextareaAutosize
                          value={note}
                          aria-label="empty textarea"
                          minRows={3}
                          placeholder="Add Notes"
                          style={{
                            width: "100%",
                            padding: "20px",
                            borderRadius: "10px",
                            fontSize: "16px",
                          }}
                          onChange={handleNotesChange}
                          readOnly={!businessId}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {editID == "" ? (
                          <Box mt={3} sx={{ textAlign: "center" }}>
                            <Button type="submit" variant="contained">
                              Submit
                            </Button>
                          </Box>
                        ) : (
                          <Box mt={3} sx={{ textAlign: "center" }}>
                            <Button type="submit" variant="contained">
                              Save
                            </Button>
                            <Button
                              sx={{ marginLeft: "5px", backgroundColor: "red" }}
                              onClick={fncCancelNotes}
                              variant="contained"
                            >
                              Cancel
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Card>
            </Box> */}
          </Grid>
          <Grid item md={3}>
            <Card sx={{ minHeight: "150px", py: 2, px: 1, mb: 2 }}>
              <Stack direction="column" justifyContent="center" m={2}>
                <Typography variant="h6" gutterBottom>
                  Register Details
                </Typography>

                <Stack direction="row" justifyContent="flex-start" ml={2}>
                  <Typography variant="p" gutterBottom>
                    <strong>First Name: </strong>
                    {profileData.user_data?.first_name
                      ? capitalCase(profileData.user_data?.first_name)
                      : "-"}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" ml={2}>
                  <Typography variant="p" gutterBottom>
                    <strong>Last Name: </strong>
                    {profileData.user_data?.last_name
                      ? capitalCase(profileData.user_data?.last_name)
                      : "-"}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" ml={2}>
                  <Typography variant="p" gutterBottom>
                    <strong> Email: </strong>
                    {profileData.user_data?.email
                      ? profileData.user_data?.email
                      : "-"}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" ml={2}>
                  <Typography variant="p" gutterBottom>
                    <strong>Mobile: </strong>
                    {profileData.user_data?.mobile_number
                      ? profileData.user_data?.mobile_number
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
            {reaminingApprovalsField?.length > 0 && (
              <Card
                sx={{
                  minHeight: "150px",
                  maxHeight: "500px",
                  overflow: "scroll",
                  py: 2,
                  px: 1,
                  backgroundColor: "#a31313",
                }}
              >
                <Stack direction="column" justifyContent="center" m={2}>
                  <Typography variant="h6" style={{
                      color: "white",
                    }} gutterBottom>
                  Pending fields for vendor approval
                  </Typography>

                  <Stack direction="row" justifyContent="flex-start" ml={2}>
                    <div style={{
                      color: "white",
                    }}>
                      {reaminingApprovalsField?.map((ele, index) => (
                        <div key={index} style={{ marginBottom: "16px",color: "white", }}>
                          <Typography variant="h6" gutterBottom>
                            {ele.key}
                          </Typography>
                          <ul style={{ paddingLeft: "20px", marginTop: "8px" }}>
                            {ele.values?.map((value, subIndex) => (
                              <li key={subIndex}>
                                <Typography style={{
                                  color: "white",
                                }} variant="body2">
                                  {formatText(value)}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </Stack>
                </Stack>
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
