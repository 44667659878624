import { DateTime } from "luxon";
import { userAxios } from "../../utils/axios/user.axios";
import {
  searchLanguageApi,
  searchSkillApi,
  searchServiceApi,
  searchIndustryApi,
  getOrgIdByProfileIdApi,
  getServiceTeckStacksApi,
  fetchTeckStackBySearchApi,
  addServices,
  deleteServices,
  allFunctionIndustry,
  fetchTagsBySearchApi,
  getServiceTagsApi,
  getAssignedVendorApi,
  selectVerifiedVendorApi,
  getVendorByPortfolioIds,
  getAllCategories,
  searchProductCodeIndustryApi,
  searchProductCodeSubIndustryApi,
  searchBenchStrengthLocationApi,
  getTechStackForteApi,
  searchProductCodeDeliveryClassApi,
  searchBenchResourceBifurcationApi,
  getTechStackListApi,
  fetchAllServicesApi,
  fetchAllIndustriesApi,
  fetchSubIndustryApi,
  fetchAllDeliveryClassApi,
  fetchAllSubIndustryApi,
} from "../apis.utils";
import { ORG_ID } from "../constant";
import { fetchAllNotificationsApi } from "../apis.utils";
import { createQueryString } from "./global";
import { vendorAxios } from "../axios/vendor.axios";

export const modifyBusinessProfiles = (data) => {
  return data.map((profile) => ({
    ...profile,
    created_at: DateTime.fromISO(profile.created_at).toFormat("DD"),
    updated_at: DateTime.fromISO(profile.updated_at).toFormat("DD"),
    mobile: profile.country_code + "-" + profile.mobile_number,
  }));
};

export const addTechstack = (data) => {
  return userAxios.post("/tech_stack", { data });
};

export const searchLanguageHandler = (term) => {
  const query = createQueryString({
    term,
  });
  return userAxios.get(searchLanguageApi + query);
};

export const searchSkillHandler = (term) => {
  const query = createQueryString({
    term,
  });
  return userAxios.get(searchSkillApi + query);
};


export const searchServiceHandler = (query) => {
  const term = createQueryString({
    query,
  });
  return userAxios.get(searchServiceApi + term);
};

export const newSearchServiceHandler = (query) => {
  const term = createQueryString({
    query,
  });
  return userAxios.get(fetchAllServicesApi + term);
};

export const searchIndustryHandler = (query) => {
  const term = createQueryString({
    query,
  });
  return userAxios.get(searchIndustryApi + term);
};

export const newSearchIndustryHandler = (query) => {
  const term = createQueryString({
    query,
  });
  return userAxios.get(fetchAllIndustriesApi + term);
};

export const newSearchSubIndustryHandler = (query) => {
  const term = createQueryString({
    query,
  });
  return userAxios.get(fetchAllSubIndustryApi + term);
}

export const newSearchDeliveryclassHandler = (query) => {
  const term = createQueryString({
    query,
  });
  return userAxios.get(fetchAllDeliveryClassApi + term);
}

//product code api
export const productCodeIndustryHandler = (query) => {
  const term = createQueryString({
    query,
  });
  return userAxios.get(searchProductCodeIndustryApi + term);
};

export const productCodeSubIndustryHandler = ({ query, selectIndustryId }) => {
  const term = createQueryString({
    query,
    selectIndustryId,
  });
  return userAxios.get(searchProductCodeSubIndustryApi + term);
};

//location code bench strength
export const benchStrengthLocationHandler = (search_term) => {
  const term = createQueryString({
    search_term,
  });
  return vendorAxios.get(searchBenchStrengthLocationApi + term);
};

export const benchResourceBifurcationHandler = (search_term) => {
  const term = createQueryString({
    search_term,
  });
  return vendorAxios.get(searchBenchResourceBifurcationApi + term);
};

export const benchStrengthTechstackHandler = (query, id) => {
  const term = createQueryString({
    search_term: query,
    rb_id: id,
  });
  return vendorAxios.get(getTechStackListApi + term);
};

export const storeOrgId = (token) => localStorage.setItem(ORG_ID, token);

export const getOrgId = () => localStorage.getItem(ORG_ID);

export const removeOrgId = () => {
  localStorage.removeItem(ORG_ID);
};
export const getAllNotificationHandler = (offset) => {
  const term = createQueryString({
    offset,
  });
  return userAxios.get(fetchAllNotificationsApi + term);
};

export const getOrgIdByProfileId = (id) => {
  const term = createQueryString({
    profile_id: id,
    profile_type: "vendor",
  });
  return userAxios.get(getOrgIdByProfileIdApi + term);
};

export const serviceFormatter = (data) => {
  const sortedData = data.sort((a, b) => (a.path > b.path ? 1 : -1));
  var arr = [];

  

  sortedData.forEach((ele) => {
    
    if (!ele.parent_id) {
      
      return arr.push({
        path: [ele.title],
        id: ele._id,
        title: ele.title,
      });
    }
    arr.push({
      path: ele.path?.split("/"),
      id: ele._id,
      title: ele.title,
    });
  });

  return arr;
};

export const serviceFormatterLevelOne = (data) => {
  const sortedData = data.sort((a, b) => (a.path > b.path ? 1 : -1));
  var arr = [];

  

  sortedData.forEach((ele) => {
    
    if (!ele.parent_id) {
      
      return arr.push({
        path: [ele.title],
        id: ele._id,
        title: ele.title,
      });
    }
    arr.push({
      path: [ele.title],
      id: ele._id,
      title: ele.title,
    });
  });

  return arr;
};

export const deliveryclassFormatter = (data) => {
  const sortedData = data.sort((a, b) => (a.path > b.path ? 1 : -1));
  var arr = [];

  sortedData.forEach((ele) => {
    if (!ele.parent_id) {
      return arr.push({
        path: [ele.name],
        id: ele._id,
        title: ele.name,
      });
    }
    arr.push({
      path: ele.path?.split("/"),
      id: ele._id,
      title: ele.name,
    });
  });

  return arr;
};

export const countryFormatter = (data) => {
  const sortedData = data.sort((a, b) => (a.path > b.path ? 1 : -1));
  var arr = [];

  sortedData.forEach((ele) => {
    if (!ele.parent_id) {
      return arr.push({
        path: [ele],
        title: ele,
      });
    }
    arr.push({
      path: ele.path?.split("/"),
      title: ele,
    });
  });

  return arr;
};

export const techstackFormatter = (data) => {
  const sortedData = data.sort((a, b) => (a.path > b.path ? 1 : -1));
  var arr = [];

  sortedData.forEach((ele) => {
    if (!ele.parent_id) {
      return arr.push({
        path: [ele.tech_stack],
        id: ele.id,
        title: ele.tech_stack,
      });
    }
    arr.push({
      path: ele.path?.split("/"),
      id: ele.id,
      title: ele.tech_stack,
    });
  });

  return arr;
};

export const fetchAllTechStackByService = async (id) => {
  const query = createQueryString({
    service_id: id,
  });
  return userAxios.get(getServiceTeckStacksApi + query);
};

export const fetchAllTageByService = async (id) => {
  const query = createQueryString({
    service_id: id,
  });
  return userAxios.get(getServiceTagsApi + query);
};

export const fetchAllTechStackBySearch = async (search) => {
  const query = createQueryString({
    query: search,
  });
  return userAxios.get(fetchTeckStackBySearchApi + query);
};

export const fetchAllTagsBySearch = async (search) => {
  const query = createQueryString({
    query: search,
  });
  return userAxios.get(fetchTagsBySearchApi + query);
};

export const updateServiceHandler = async (data) => {
  return userAxios.put(addServices + "/" + data._id, { data });
};

export const deleteVendorProfileHandler = async (id) => {
  return;
};

export const serviceSlugValidate = async (search) => {};

export const industrySlugValidate = async (search) => {};

export const deleteServiceHandler = async (id) => {
  return userAxios.delete(deleteServices + "/" + id);
};

export const addService = async (data) => {
  return userAxios.post(addServices, { data });
};

export const deleteIndustryHandler = async (id) => {
  return userAxios.delete(allFunctionIndustry + "/" + id);
};

export const addIndustry = async (data) => {
  return userAxios.post(allFunctionIndustry, { data });
};

export const selectVerifiedVendor = async (data) => {
  return vendorAxios.put(selectVerifiedVendorApi, { ...data });
};

export const updateIndustryHandler = (data) => {
  return userAxios.put(allFunctionIndustry + "/" + data._id, { data });
};

export const fetchAssignedVendorHandler = (data) => {
  return vendorAxios.get(getAssignedVendorApi);
};

export const assignVendorToProfileHandler = (data) => {
  return vendorAxios.put(getAssignedVendorApi, data);
};

export const deleteAssignVendorHandler = (id) => {
  return vendorAxios.delete(getAssignedVendorApi + "/" + id);
};

export const getVenodorPortifoliosByIDHandler = (data) => {
  return vendorAxios.get(getVendorByPortfolioIds + "=[" + data + "]");
};

export const getAllCategory = async (data) => {
  return userAxios.get(getAllCategories);
};

//sub industry api
export const fetchAllSubIndustry = async (id) => {
  // const query = createQueryString({
  //   service_id: id,
  // });
  return userAxios.get(searchProductCodeSubIndustryApi + "/" + id);
};

export const deliveryClasskHandler = (search_term) => {
  const term = createQueryString({
    search_term,
  });
  return vendorAxios.get(searchProductCodeDeliveryClassApi + term);
};

// export const searchIndustryHandler = (query) => {
//   const term = createQueryString({
//     query,
//   });
//   return userAxios.get(searchServiceApi + term);
// };
