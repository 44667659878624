import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchProfilesByAdvanceFilterThunk } from "../store/slices/vendor/vendor.slice";
import { advanceFilterFieldType } from "../utils/enum";
import { set } from "lodash";

const INIT_STATE = {
  services: {
    search_type: "in",
    values: [],
  },
  sub_services: {
    search_type: "in",
    values: [],
  },
  state: {
    search_type: "is",
    value: "",
  },
  city: {
    search_type: "in",
    values: [],
  },
};

function useAdvanceProfileFilter({ search, type }) {
  const [state, setState] = useState(INIT_STATE);
  const dispatch = useDispatch();

  function applyFilter() {
    let isFieldSet = false;
    let newFilterState = [
      {
        field_name: "name",
        search_type: "start_with",
        value: search,
      },
    ];
    Object.keys(state).forEach((key) => {
      const filterObj = state[key];
      if (
        Object.keys(filterObj).length > 0 &&
        filterObj.search_type &&
        (filterObj.values || filterObj.value)
      ) {
        isFieldSet = true;
        newFilterState.push({
          field_name: key,
          ...filterObj,
        });
      }
    });
    if (!isFieldSet) return;

    const newFilterStateUpdated = newFilterState
      .filter((item) => {
        if (item.field_name === "services") {
          return false;
        }

        if (
          item?.values &&
          Array.isArray(item?.values) &&
          item?.values.length === 0
        ) {
          return false;
        }
        if (
          item.value !== undefined &&
          (item.value === "" || item.value === null)
        ) {
          return false;
        }

        return true;
      })
      .map((item) => {
        if (item.field_name === "sub_services") {
          return {
            ...item,
            field_name: "service",
          };
        }
        return item;
      });

    dispatch(
      fetchProfilesByAdvanceFilterThunk({
        filter_data: [...newFilterStateUpdated],
        page: 1,
        profile_stage: type,
        limit: 10,
      })
    );
  }

  function resetFilter() {
    setState(INIT_STATE);
  }

  function _getNewFieldValuesForTextType(fieldName, search_type, value) {
    let fieldObj = state[fieldName];
    if (search_type !== null) {
      fieldObj.search_type = search_type;
    }
    if (value || value === "") {
      fieldObj.values = [value];
    }
    return fieldObj;
  }

  function _getNewFieldValuesForDropdownType(
    field_name,
    search_type,
    value,
    type = "ADD"
  ) {
    let fieldObj = state[field_name];
    if (type === "ADD") {
      if (search_type !== null) {
        fieldObj.search_type = search_type;
      }
      if (value) {
        if (typeof fieldObj.values === "undefined") {
          fieldObj.values = [value.value];
        } else {
          fieldObj.values.push(value.value);
        }
      }
      return fieldObj;
    }

    fieldObj.values = fieldObj.values.filter((ele) => ele !== value);
    return fieldObj;
  }

  function _getNewFieldValuesForDateType(field_name, search_type, value, max) {
    let fieldObj = state[field_name];
    if (search_type !== null) {
      fieldObj.search_type = search_type;
    }

    if (value && max) {
      fieldObj.values = [value, max];
    }

    if (value && !max) {
      fieldObj.values[0] = value;
    }

    if (!value && max) {
      fieldObj.values[1] = max;
    }

    return fieldObj;
  }

  function handleFilterValueChange(type, fieldName, search_type, value, max) {
    if (type === advanceFilterFieldType.text) {
      setState((data) => ({
        ...data,
        [fieldName]: _getNewFieldValuesForTextType(
          fieldName,
          search_type,
          value
        ),
      }));
    }

    if (type === advanceFilterFieldType.boolean) {
      setState((data) => ({
        ...data,
        [fieldName]: {
          ...data[fieldName],
          values: [search_type],
        },
      }));
    }

    if (type === advanceFilterFieldType.dropdown) {
      setState((data) => ({
        ...data,
        [fieldName]: _getNewFieldValuesForDropdownType(
          fieldName,
          search_type,
          value
        ),
      }));
    }

    if (type === advanceFilterFieldType.date) {
      setState((data) => ({
        ...data,
        [fieldName]: _getNewFieldValuesForDateType(
          fieldName,
          search_type,
          value,
          max
        ),
      }));
    }
  }

  function deleteFilterValue(type, fieldName, search_type, value) {
    setState((data) => ({
      ...data,
      [fieldName]: _getNewFieldValuesForDropdownType(
        fieldName,
        search_type,
        value,
        "DELETE"
      ),
    }));
  }

  return {
    state,
    applyFilter,
    setState,
    resetFilter,
    handleFilterValueChange,
    deleteFilterValue,
  };
}

export default useAdvanceProfileFilter;
