import TreeView from "@mui/lab/TreeView";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Icon,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import SimpleBar from "simplebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { inputType } from "../../utils/enum";
import Element from "../Form/Element";
import { useDispatch, useSelector } from "react-redux";
import { getServicesListThunk } from "../../store/slices/onboard/onboard.slice";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  benchStrengthLocationHandler,
  countryFormatter,
} from "../../utils/global/vendor.global";
import SearchCountry from "../Modal/SearchCountry";
import { indianRegions, indianRegionsSearch } from "../../utils/constant";

const FilterProfiles = ({ handleFilterClose, filterState }) => {
  const dispatch = useDispatch();
  const { servicesList } = useSelector(({ onBoard }) => onBoard.data);

  const [l0Services, setL0Services] = useState([]);
  const [l1Services, setL1Services] = useState([]);
  const [citys, setCitys] = useState([]);

  useEffect(() => {
    dispatch(getServicesListThunk());
  }, []);

  useEffect(() => {
    const l0 = servicesList.map((item) => ({
      title: item?.title,
      _id: item?._id,
    }));
    setL0Services(l0);

    const l1 = servicesList
      .map((item) =>
        item?.sub_services.map((ele) => ({
          title: ele?.title,
          _id: ele?._id,
        }))
      )
      .flat();
    // console.log("l1", l1);
    setL1Services(l1);
  }, [servicesList]);

  const filterFormik = useFormik({
    initialValues: {
      services:
        filterState.state.services.values.length > 0 &&
        filterState.state.services.values[0],
      sub_services:
        filterState.state.sub_services.values.length > 0 &&
        filterState.state.sub_services.values,
      state: filterState.state.state.value || "",
      city: "",
    },
    // onSubmit: makeDataForFilter,
  });

  useEffect(() => {
    const filteredL1 = servicesList.filter(
      (item) => item?._id === filterFormik.values.services
    );

    const l1 = filteredL1[0]?.sub_services.map((elem) => ({
      _id: elem._id,
      title: elem.title,
    }));

    setL1Services(l1);
  }, [filterFormik.values.services]);

  const handeleChangeCity = (e) => {
    const cityArrayData = e.target.value.trim();
    if (cityArrayData === "") return;
    const currentCities = filterState.state.city.values || [];

    const updatedCities = [...currentCities, cityArrayData];
    setCitys(updatedCities);
    filterState.setState((prev) => ({
      ...prev,
      city: {
        ...prev.city,
        values: updatedCities,
      },
    }));

    filterFormik.setValues((prev) => ({
      ...prev,
      city: "",
    }));
  };

  const handleDelete = (val) => {
    const updatedCityArray = filterState.state.city.values.filter(
      (city) => city !== val
    );

    // Update both the local state and the filter state
    setCitys(updatedCityArray);

    filterState.setState((prev) => {
      return {
        ...prev,
        city: {
          ...prev.city,
          values: updatedCityArray,
        },
      };
    });
  };

  const handleResetFilter = () => {
    filterState.resetFilter();
    filterFormik.setValues({
      services: "",
      sub_services: "",
      state: "",
      city: "",
    });
  };

  return (
    <>
      <Container>
        <Box>
          <Grid container>
            <Grid item md={12}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div" pt={2} mb={1}>
                  Filter By
                </Typography>
                <Icon
                  icon="eva:close-outline"
                  style={{ fontSize: "27px", cursor: "pointer" }}
                  onClick={handleFilterClose}
                />
              </Stack>
              <Divider />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ flexBasis: "430px" }}>
                  <Grid container>
                    <Grid item md={12} p={1}>
                      <Element
                        label="Services"
                        inputProps={{
                          name: "services",
                          onChange: (e) => {
                            filterFormik.handleChange(e);
                            filterState.setState((prev) => {
                              return {
                                ...prev,
                                services: {
                                  ...prev.services,
                                  values: [e.target.value],
                                },
                              };
                            });
                          },
                          onBlur: filterFormik.handleBlur,
                        }}
                        value={filterFormik.values.services}
                        options={l0Services}
                        errorText={
                          filterFormik.touched.services &&
                          filterFormik.errors.services
                        }
                        eletype={inputType.select}
                        icons={
                          <ArrowDropDownIcon
                            sx={{ position: "absolute", right: 20, bottom: 24 }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={12} p={1}>
                      <Element
                        label="Sub Services"
                        inputProps={{
                          name: "sub_services",
                          onChange: (e) => {
                            filterFormik.handleChange(e);
                            filterState.setState((prev) => {
                              return {
                                ...prev,
                                sub_services: {
                                  ...prev.sub_services,
                                  values: e.target.value,
                                },
                              };
                            });
                          },
                          onBlur: filterFormik.handleBlur,
                        }}
                        value={filterFormik.values.sub_services}
                        options={l1Services}
                        errorText={
                          filterFormik.touched.sub_services &&
                          filterFormik.errors.sub_services
                        }
                        eletype={inputType.multiselect}
                        icons={
                          <ArrowDropDownIcon
                            sx={{ position: "absolute", right: 20, bottom: 24 }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={12} p={1}>
                      <Element
                        label="Location (State)"
                        inputProps={{
                          name: "state",
                          onChange: (e) => {
                            filterFormik.handleChange(e);
                            filterState.setState((prev) => ({
                              ...prev,
                              state: {
                                ...prev.state,
                                value: e.target.value,
                              },
                            }));
                          },
                          onBlur: filterFormik.handleBlur,
                        }}
                        value={filterFormik.values.state}
                        options={indianRegionsSearch}
                        errorText={
                          filterFormik.touched.state &&
                          filterFormik.errors.state
                        }
                        eletype={inputType.select}
                        icons={
                          <ArrowDropDownIcon
                            sx={{ position: "absolute", right: 20, bottom: 24 }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={12} p={1}>
                      <Element
                        label="Location (City)"
                        inputProps={{
                          name: "city",
                          onChange: (e) => {
                            filterFormik.handleChange(e);
                          },
                          onBlur: filterFormik.handleBlur,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handeleChangeCity(e);
                            }
                          },
                        }}
                        value={filterFormik.values.city}
                        errorText={
                          filterFormik.touched.city && filterFormik.errors.city
                        }
                        eletype={inputType.input}
                        icons={
                          <ArrowDropDownIcon
                            sx={{ position: "absolute", right: 20, bottom: 24 }}
                          />
                        }
                      />
                      {filterState?.state?.city?.values?.map((val, index) => (
                        <ListItem key={index} sx={{ display: "contents" }}>
                          <Chip
                            label={val}
                            onDelete={() => handleDelete(val)}
                            sx={{ marginRight: "1rem", marginBottom: "1rem" }}
                          />
                        </ListItem>
                      ))}
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Box
                    sx={{ textAlign: "center", width: "100%" }}
                    mt={3}
                    mb={3}
                  >
                    <Button
                      type="buttom"
                      variant="contained"
                      size="large"
                      sx={{ padding: "10px 30px", marginRight: "10px" }}
                      onClick={() => {
                        handleFilterClose();
                        filterState.applyFilter();
                        // filterFormik.handleSubmit();
                      }}
                    >
                      Apply Filter
                    </Button>

                    <Button
                      color="inherit"
                      size="large"
                      sx={{ padding: "10px 20px", border: "2px solid black" }}
                      onClick={handleResetFilter}
                    >
                      Reset Filter
                    </Button>
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
export default FilterProfiles;
