import axios from "axios";
import {
  assesmenUpload,
  assesmenUploadFile,
  assignUserSALogAPI,
  changeStatusOfLead,
  cliedtData,
  fetchAllCvsByPartner,
  fetchAllRA,
  fetchAllSALeadsApi,
  fetchAllSAUsersApis,
  fetchAllTechStackList,
  fetchRAById,
  fetchRAByLineItems,
  hireTelentByEMB,
  postAssignUser,
  postShortlistApi,
  putEmbJdUpload,
  putShortlistApi,
  raEnableVendors,
  searchClientRequirment,
  uploadAssignmentByEMB,
  uploadCVByEMB,
} from "../../../utils/apis.utils";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { assignUserTemplate } from "../../../utils/template.util";
import { uploadEmbByEMBThunk } from "./ra.slice";

export const fetchAllRALeadsHandler = async (__, thunkApi) => {
  const { sa } = thunkApi.extra.apiService;
  const response = await sa.get(fetchAllSALeadsApi);
  return response.data;
};

export const fetchAllRALeadsPaginationHandler = async (
  { search, page = 1 },
  thunkApi
) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(
    fetchAllRA + "?page=" + page + "&query=" + search
  );
  return response;
};

export const fetchRAAssignUserHandler = async (_, thunkApi) => {
  const { sa } = thunkApi.extra.apiService;
  const response = await sa.get(
    fetchAllSAUsersApis + `?environment=${process.env.REACT_APP_SA_ENVIRONMENT}`
  );
  return response.data;
};

export const postRAAssignUserHandler = async (data, thunkApi) => {
  const { name, email, lead_line_item_id, assigned_user_id, lead_internal_id } =
    data.data;
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.post(postAssignUser, {
    data: {
      lead_line_item_id,
      assigned_user_id,
    },
  });
  // const assignUserData = assignUserTemplate(
  //   lead_internal_id,
  //   new Date().toLocaleString(),
  //   assigned_user_id,
  //   name,
  //   email
  // );

  // await logs.post(assignUserSALogAPI, {
  //   leadId: lead_line_item_id,
  //   meta: {
  //     ...assignUserData,
  //   },
  // });

  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const fetchAllRaEnableVendorHandler = async (
  { search = "", page = 1 },
  thunkApi
) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(
    raEnableVendors + "?page=" + page + "&query=" + search
  );
  // thunkApi.dispatch(
  //   showSuccessToast({
  //     message: response.message,
  //     vertical: "top",
  //     horizontal: "right",
  //   })
  // );
  return response;
};

export const searchClientRequirmentHandler = async (data, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(
    `${searchClientRequirment}?query=${data?.search}`
  );
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response;
};

export const fetchRaLeadByIdHandler = async (id, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchRAById + "/" + id);
  return response.data;
};

export const fetchRaLeadLineItemsHandler = async (id, thunkApi) => {
  const { sa } = thunkApi.extra.apiService;
  const response = await sa.get(fetchRAByLineItems + "/" + id);
  return response.data;
};

export const fetchAllCvsByPartnerHandler = async (id, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchAllCvsByPartner + "/" + id);
  return response.data;
};

export const changeStatusOfLeadHandler = async (
  { lead_id, applicant_id, status, remarks, type },
  thunkApi
) => {
  const payload =
    type === "rejected"
      ? {
          emb_applicant_status: "rejected",
          rejection_dropdown_reason: status,
          reason: remarks,
        }
      : {
          emb_applicant_status: status,
          reason: remarks,
        };
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(
      changeStatusOfLead + "/" + lead_id + "/" + applicant_id,
      payload
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
  }
};

export const uploadCVByEMBHandler = async (
  { id, formData, leadId },
  thunkApi
) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.put(
    uploadCVByEMB + "/" + leadId + "/" + id,
    formData
  );
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const uploadAssignmentByEMBHandler = async (
  { id, requirement_id, data },
  thunkApi
) => {
  try {
    const { client } = thunkApi.extra.apiService;
    const response = await client.post(
      `${uploadAssignmentByEMB}${requirement_id}/candidate/${id}?type=assignment`,
      { data: data }
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    throw err;
  }
};

export const hireByEMBHandler = async (
  { id, requirement_id, data },
  thunkApi
) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(
      `${hireTelentByEMB}${requirement_id}/${id}`,
      { data: data }
    );
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    throw err;
  }
};

export const clientDataEMBHandler = async ({ requirement_id }, thunkApi) => {
  try {
    const { client } = thunkApi.extra.apiService;
    const response = await client.get(`${cliedtData}${requirement_id}`);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    throw err;
  }
};

export const uploadAssesmentByEMBHandler = async ({ data }, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.put(assesmenUpload, { data: data });
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const uploadAssesmentFileByEMBHandler = async (
  { formData, data },
  thunkApi
) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.post(
    assesmenUploadFile + "/" + data?.applicant_id,
    formData
  );
  if (response) {
    const payload = {
      requirement_id: (data?.requirement_id).toString(),
      applicant_id: (data?.applicant_id).toString(),
      assignment_data: [
        {
          file_name: response?.data?.file_name,
          url: response?.data?.url,
        },
      ],
    };
    try {
      const result = await thunkApi.dispatch(
        uploadEmbByEMBThunk({ data: payload })
      );

      if (result.type === "uploadAssedmentByEMB/fulfilled") {
        thunkApi.dispatch(
          showSuccessToast({
            message: response.message, // Note: Should this be result.payload.message?
            vertical: "top",
            horizontal: "right",
          })
        );
      }
    } catch (error) {
      // Handle error here
      console.error("Error in uploadEmbByEMBThunk:", error);
      throw error; // Re-throw if you want the error to propagate
    }
  }
};

export const uploadFileByEMBHandler = async (
  { formData, applicant_id },
  thunkApi
) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.post(
    assesmenUploadFile + "/" + applicant_id,
    formData
  );
  thunkApi.dispatch(
    showSuccessToast({
      message: response.message,
      vertical: "top",
      horizontal: "right",
    })
  );
  return response.data;
};

export const fetchAllTechStackListHandler = async ({ search }, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchAllTechStackList + "?query=" + search);
  return response.data;
};

export const postShorlistedVendorsHandler = async ({ data, id }, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;

    const response = await vendor.put(putShortlistApi + "/" + id, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
  }
};

export const putEmbJdUploadThunkHandler = async ({ data, id }, thunkApi) => {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(putEmbJdUpload + "/" + id, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
  }
};
