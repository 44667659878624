import { FormControl, TextField } from "@mui/material";
import { shortlistingFilterFieldType } from "../../utils/enum";

function Text({
  width = "300px",
  height = "40px",
  label,
  value,
  handleFilterValueChange,
  state,
  ...rest
}) {
  function searchTextChange(e) {
    let eleValue = e.target.value;
    if (value === "employees_count") {
      // Remove any non-numeric characters
      eleValue = eleValue.replace(/\D/g, '');
    }
    handleFilterValueChange(shortlistingFilterFieldType.text, value, eleValue);
  }

  return (
    <FormControl sx={{ m: 2, width: width }}>
      <TextField
        sx={{ height: height }}
        inputProps={{ "aria-label": "Without label" }}
        value={state?.values ? state?.values[0] : ""}
        onChange={searchTextChange}
        placeholder={label}
        variant="outlined"
        label={label}
        {...rest}
      />
    </FormControl>
  );
}

export default Text;
