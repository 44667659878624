export const projectApprovalStatus = {
  accepted: "Accepted",
  rejected: "Rejected",
  pending: null,
};

export const inputType = {
  input: "input",
  select: "select",
  textarea: "textarea",
  checkbox: "checkbox",
  radio: "radio",
  autocomplete: "autocomplete",
  date: "date",
  file: "user",
  switch: "switch",
  number: "number",
  url: "url",
  checkboxgroup: "checkboxgroup",
  chip: "chip",
  multiselect: "multiselect",
};

export const projectServices = {
  desiging: "Desiging",
  development: "Development",
  marketing: "Marketing",
  others: "Others",
};

export const strings = {
  email_verify: "email_verify",
};
export const serviceType = {
  custom: "custom",
  exmyb: "service",
};

export const serviceAddType = {
  tech: "tech",
  tags: "tags",
};

export const advanceFilterFieldType = {
  text: "text",
  dropdown: "dropdown",
  boolean: "boolean",
  date: "date",
};

export const shortlistingFilterFieldType = {
  text: "text",
  dropdown: "dropdown",
  sigleSelectDropdown:"sigleSelectDropdown"
};

export const vendorVerifiedEnum = {
  pending: "pending",
  approved: "approved",
  verified: "verified",
  unverified: "unverified",
  sent_for_accounts_approval: "sent_for_accounts_approval",
  accounts_approved: "accounts_approved",
};

export const Ra_EMB_Status = {
  CV_UPLOADED: "cv_uploaded",
  SCREENING: "screening",
  SHORTLISTED: "shortlisted",
  EMB_SCHEDULE_INTERVIEW: "schedule_interview",
  CLIENT_SCHEDULE_INTERVIEW: "client_schedule_interview",
  EMB_REJECTED: "emb_rejected",
  PARTNER_HOLD: "partner_hold",
  EMB_HOLD: "emb_hold",
  CLIENT_HOLD: "emb_hold",
  EMB_SELECTED: "emb_selected",
  CV_RE_UPLOADED: "cv_re_uploaded",
};

export const timelineActionsEnum = {
  EMB_DEAL_CREATED: "EMB_DEAL_CREATED",
  EMB_ASSIGN_USER: "EMB_ASSIGN_USER",
  EMB_UPLOAD_JD: "EMB_UPLOAD_JD",
  EMB_VENDOR_SHORTLIST: "EMB_VENDOR_SHORTLIST",
  EMB_CV_VET_HR: "EMB_CV_VET_HR",
  EMB_CV_VET_INTERVIEW: "EMB_CV_VET_INTERVIEW",
  EMB_CV_MOVE_TO_CLIENT: "EMB_CV_MOVE_TO_CLIENT",
};

export const profileTableType = {
  active: "approved",
  all: "all",
};
