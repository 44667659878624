import React from "react";
import { useStyles } from "./Form.theme";

const Input = ({
  label,
  errorText,
  inputProps = {}, // Default to an empty object to avoid undefined errors
  value,
  readOnly,
  style,
  icon,
  placeholder,
  disabled,
  filterChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.formGroup}>
      {label && (
        <label className={classes.formLabel} style={style}>
          {label}
        </label>
      )}
      <input
        className={classes.formControl}
        {...inputProps}
        value={value}
        readOnly={readOnly}
        icon={icon}
        autoComplete="off"
        style={style}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={inputProps.onKeyDown || ((e) => filterChange && filterChange(e))}
      />
      {errorText && <span className={classes.formErrorStyle}>{errorText}</span>}
    </div>
  );
};

export default Input;
