import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useStyles } from "./RaCvTable";
import { useStyles as formStyles } from ".././../components/Form/Form.theme";
import { useDispatch } from "react-redux";
import {
  fetchAllCvsByPartnerThunk,
  uploadEmbByEMBThunk,
  uploadEmbFileByEMBThunk,
  uploadFileEmbByEMBThunk,
} from "../../store/slices/ra/ra.slice";
import { useParams } from "react-router-dom";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const UploadInterViewReport = ({ applicantId, onClose }) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const formClasses = formStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const getFilename = () => {
    if (!file) return null;
    const content =
      file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name;
    return file.url ? (
      <p className={classes.filename}>
        <a
          rel="noreferrer"
          href={file.url}
          target="_blank"
          style={{ textDecoration: "none", color: "grey" }}
        >
          {content}
        </a>
      </p>
    ) : (
      <p className={classes.filename}>{content}</p>
    );
  };

  const handleCVUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_type", "interview_report");

      dispatch(
        uploadFileEmbByEMBThunk({
          formData: formData,
          requirement_id: applicantId?.requirement_id,
          applicant_id: applicantId?.id,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(fetchAllCvsByPartnerThunk(params.leadId));
          onClose(false);
        });
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  return (
    <>
      <Box sx={styleModal}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            flexDirection: "column",
          }}
          className={classes.dropdownIconCss}
        >
          <label className={formClasses.formLabel}>
            Upload Interview Report
          </label>
          <div className="file-upload">
            <div className="file-select">
              <div className="file-select-button" id="fileName">
                Upload
              </div>
              <div className="file-select-name" id="noFile">
                Browse File...
              </div>
              <input
                type="file"
                name="chooseFile"
                className={formClasses.formControl}
                onChange={handleFileChange}
              />
            </div>
          </div>
          {file?.name && (
            <p className={formClasses.filename} style={{ margin: 0 }}>
              {getFilename()}
            </p>
          )}
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{ marginLeft: "5px" }}
            onClick={handleCVUpload}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UploadInterViewReport;
