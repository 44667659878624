import _ from "lodash";
import {
  fetchAllNotificationsApi,
  fetchAllSystemPermissionsApi,
  fetchAllUserPermissionsApi,
  markAllNotificationsAsReadApi,
  userApi,
} from "../../../utils/apis.utils";
import { strings } from "../../../utils/enum";
import { removeAccessToken } from "../../../utils/global/auth.global";
import { formatUserResponse } from "../../../utils/global/user.global";
import { setUserAsLogin } from "../auth/auth.slice";
import { setUserProfileDetails } from "../user/user.slice";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import timeLocalStorage from "../../../utils/timeLocalStorage";
import { ALL_PERMISSIONS, USER_PERMISSIONS } from "../../../utils/constant";


export const checkTokenValidity = async (__, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user, roles } = thunkApi.extra.apiService;
    const response = await user.get(userApi);
    const responseData = response.data;
    if (_.get(responseData, strings.email_verify)) {
      const userDetails = formatUserResponse(responseData);
      thunkApi.dispatch(setUserProfileDetails(userDetails));
      thunkApi.dispatch(setUserAsLogin());

      // Here roles init work can be done

      // if (!timeLocalStorage.getItemWithExpiry(ALL_PERMISSIONS)) {
      //   const permissions = await roles.get(fetchAllSystemPermissionsApi);

      //   timeLocalStorage.setItemWithExpiry(
      //     ALL_PERMISSIONS,
      //     JSON.stringify(_.keyBy(permissions.data, "name")),
      //     5 * 6 * 30
      //   );
      // }

      let finalResponse;

      // For dev only
      try {
        const userPermissions = await roles.get(fetchAllUserPermissionsApi);
        const userPermissionsWithLabelKeyAdded = userPermissions.data?.map(
          (ele) => ({
            ...ele,
            id: ele.resource.id,
          })
        );
        finalResponse = _.keyBy(userPermissionsWithLabelKeyAdded, "id");
      } catch (err) {}

      // if (!timeLocalStorage.getItemWithExpiry(USER_PERMISSIONS)) {
      //   const userPermissions = await roles.get(fetchAllUserPermissionsApi);
      //   const userPermissionsWithLabelKeyAdded = userPermissions.data?.map(
      //     (ele) => ({
      //       ...ele,
      //       id: ele.resource.id,
      //     })
      //   );
      //   finalResponse = _.keyBy(userPermissionsWithLabelKeyAdded, "id");
      //   timeLocalStorage.setItemWithExpiry(
      //     USER_PERMISSIONS,
      //     JSON.stringify(finalResponse),
      //     5 * 30
      //   );
      // } else {
      //   const userPermissions =
      //     timeLocalStorage.getItemWithExpiry(USER_PERMISSIONS);
      //   return JSON.parse(userPermissions);
      // }

      return finalResponse;
    }
    removeAccessToken();
    return Promise.reject();
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const fetchAllNotificationsHandler = async (__, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(fetchAllNotificationsApi);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const markAllNotificationsAsReadHandler = async (__, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(markAllNotificationsAsReadApi);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};
