import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles as formStyles } from "../../components/Form/Form.theme";
import { useStyles } from "./RaCvTable";
import Element from "../../components/Form/Element";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clientDataEMBThunk,
  fetchAllCvsByPartnerThunk,
  hireByEMBThunk,
} from "../../store/slices/ra/ra.slice";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const HireCondidate = ({ applicantId, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const [clientData, setClientData] = useState({});
  const [hiringData, setHiringData] = useState(null);

  // Initialize hiringData when applicantId or clientData changes
  useEffect(() => {
    if (applicantId && clientData?.company_name) {
      setHiringData({
        candidate_name: applicantId?.applicant_name || "",
        client_applicant_status: "hired",
        companyHiringDetails: {
          company_name: clientData?.company_name || "",
          company_address: clientData?.company_address?.address_line_1 || "",
          start_date: new Date().toISOString().split('T')[0], // Today's date as default
          authorized_signatory_name: clientData?.client_name || "",
          authorized_signatory_email: clientData?.client_email || "",
        },
      });
    }
  }, [applicantId, clientData]);

  // Handler for candidate name
  const handleCandidateNameChange = (e) => {
    setHiringData((prev) => ({
      ...prev,
      candidate_name: e.target.value,
    }));
  };

  // Handler for company name
  const handleCompanyNameChange = (e) => {
    setHiringData((prev) => ({
      ...prev,
      companyHiringDetails: {
        ...prev.companyHiringDetails,
        company_name: e.target.value,
      },
    }));
  };

  // Handler for company address
  const handleCompanyAddressChange = (e) => {
    setHiringData((prev) => ({
      ...prev,
      companyHiringDetails: {
        ...prev.companyHiringDetails,
        company_address: e.target.value,
      },
    }));
  };

  // Handler for start date
  const handleStartDateChange = (e) => {
    setHiringData((prev) => ({
      ...prev,
      companyHiringDetails: {
        ...prev.companyHiringDetails,
        start_date: e.target.value,
      },
    }));
  };

  // Handler for signatory name
  const handleSignatoryNameChange = (e) => {
    setHiringData((prev) => ({
      ...prev,
      companyHiringDetails: {
        ...prev.companyHiringDetails,
        authorized_signatory_name: e.target.value,
      },
    }));
  };

  // Handler for signatory email
  const handleSignatoryEmailChange = (e) => {
    setHiringData((prev) => ({
      ...prev,
      companyHiringDetails: {
        ...prev.companyHiringDetails,
        authorized_signatory_email: e.target.value,
      },
    }));
  };

  const handleCondidateHire = () => {
    if (!hiringData) return;
    
    const payload = {
      id: applicantId?.id,
      requirement_id: applicantId?.requirement_id,
      data: hiringData,
    };
    
    dispatch(hireByEMBThunk(payload))
      .unwrap()
      .then(() => {
        if (onClose) onClose();
        dispatch(fetchAllCvsByPartnerThunk(params.leadId));
      })
      .catch((error) => {
        console.error("Error submitting assignment:", error);
      });
  };

  useEffect(() => {
    if (applicantId?.requirement_id) {
      dispatch(
        clientDataEMBThunk({ requirement_id: applicantId?.requirement_id })
      ).then((res) => {
        if (res?.type === "clientByEMB/fulfilled") {
          setClientData(res.payload);
        } else {
          console.log("Error in fetching client data");
        }
      });
    }
  }, [applicantId?.requirement_id]);

  if (!hiringData) {
    return <Box sx={styleModal}>Loading hiring data...</Box>;
  }

  return (
    <Box sx={styleModal}>
      <Typography
        variant="h5"
        sx={{
          color: "#4d4d4d",
          fontWeight: "600",
          paddingBottom: "10px",
        }}
      >
        Hire Talent
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
        className={classes.dropdownIconCss}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Element
              disabled={true}
              label={"Candidate Name"}
              eletype="input"
              value={hiringData.candidate_name}
              inputProps={{
                type: "text",
                onChange: handleCandidateNameChange,
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Element
              label={"Start Date"}
              eletype="input"
              value={hiringData.companyHiringDetails.start_date}
              inputProps={{
                type: "date",
                onChange: handleStartDateChange,
              }}
              fullWidth
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Element
            disabled={true}
              label={"Company Name"}
              eletype="input"
              value={hiringData.companyHiringDetails.company_name}
              inputProps={{
                type: "text",
                onChange: handleCompanyNameChange,
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Element
            disabled={true}
              label={"Company Address"}
              eletype="input"
              value={hiringData.companyHiringDetails.company_address}
              inputProps={{
                type: "text",
                onChange: handleCompanyAddressChange,
              }}
              fullWidth
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Element
            disabled={true}
              label={"Authorized Signatory Name"}
              eletype="input"
              value={hiringData.companyHiringDetails.authorized_signatory_name}
              inputProps={{
                type: "text",
                onChange: handleSignatoryNameChange,
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Element
            disabled={true}
              label={"Authorized Signatory Email"}
              eletype="input"
              value={hiringData.companyHiringDetails.authorized_signatory_email}
              inputProps={{
                type: "email",
                onChange: handleSignatoryEmailChange,
              }}
              fullWidth
            />
          </Box>
        </Box>

        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{ marginLeft: "5px", mt: 2 }}
          onClick={handleCondidateHire}
        >
          Confirm & Hire
        </Button>
      </Box>
    </Box>
  );
};

export default HireCondidate;
