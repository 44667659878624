import { Card, Chip, FormControl, ListItem, TextField } from "@mui/material";
import React, { useState, useMemo } from "react";
import _ from "lodash";
import { searchServiceHandler } from "../../utils/global/vendor.global";
import { Box } from "@mui/system";
import TreeList from "../Modal/TreeList";
import { shortlistingFilterFieldType } from "../../utils/enum";
import { useDispatch } from "react-redux";
import {
  fetchAllTechStackByIDsThunk,
  setEmptyTechStackAndTags,
} from "../../store/slices/shortlisting/shortlisting.slice";

function ServiceSearch({
  onBlur,
  label,
  placeholder,
  formatter,
  value = [],
  handler = searchServiceHandler,
  name,
  handleFilterValueChange,
  deleteFilterValue,
  serviceSelected,
  setSelectedServiceId,
}) {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");

  const handleTextChange = useMemo(() => {
    const _fn = _.debounce((value) => {
      if (!value) return;
      handler(value).then((response) => {
        if (typeof formatter === "function") {
          if (response.data?.length === 0) {
            return setOpen(false);
          }
          setOptions(formatter(response.data));
          setOpen(true);
        }
      });
    }, 500);

    return function handleChange(e) {
      const value = e.target.value;
      setSearch(value);
      _fn(value);
    };
  }, []);

  const dispatch = useDispatch();

  function handleDelete(idx) {
    const newServicesData = serviceSelected.filter((ele) => ele.name !== idx);
    if (newServicesData.length > 0)
      dispatch(
        fetchAllTechStackByIDsThunk(
          newServicesData.map((ele) => `"${ele._id}"`)
        )
      );
    else {
      dispatch(setEmptyTechStackAndTags());
    }
    setSelectedServiceId(newServicesData);
    deleteFilterValue(shortlistingFilterFieldType.dropdown, name, idx);
  }

  function handleItemClick(e) {
    if (!e.target.value?.industry_name) return;
    setSelectedServiceId((data) => {
      const newServicesData = [
        ...data,
        {
          _id: e.target.value?._id,
          name: e.target.value?.industry_name,
        },
      ];
      dispatch(
        fetchAllTechStackByIDsThunk(
          newServicesData.map((ele) => `"${ele._id}"`)
        )
      );
      return newServicesData;
    });
    handleFilterValueChange(shortlistingFilterFieldType.dropdown, name, {
      value: e.target.value?.industry_name,
      label: e.target.value?.industry_name,
    });
    setSearch("");
  }

  return (
    <FormControl sx={{ m: 2, width: "500px" }}>
      <TextField
        label={label}
        placeholder={placeholder}
        onChange={handleTextChange}
        name="treeTextChange"
        value={search}
        height="56px"
        width="500px"
        autoComplete="off"
      />
      {isOpen && (
        <Card sx={{ height: "300px", overflow: "auto" }}>
          <Box p={2}>
            {options.map((d) => (
              <TreeList
                {...d}
                // path={[d?.title]}
                onChange={handleItemClick}
                onBlur={onBlur}
                setOpen={setOpen}
                name={name}
                idName="_id"
                valueName="industry_name"
              />
            ))}
          </Box>
        </Card>
      )}
      <ListItem sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {value?.map((val) => (
          <Chip
            label={val}
            onDelete={() => handleDelete(val)}
            sx={{ marginRight: "1rem" }}
          />
        ))}
      </ListItem>
    </FormControl>
  );
}

export default ServiceSearch;
