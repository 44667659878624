import React, { useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Card,
  Box,
  Button,
  Modal,
} from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import ListHead from "../../components/Common/ListHead";
import { capitalCase } from "../../components/Common/change-case";
import Element from "../../components/Form/Element";
import { makeStyles } from "@mui/styles";
import { inputType, Ra_EMB_Status } from "../../utils/enum";
import { useDispatch } from "react-redux";
import {
  changeStatusOfLeadThunk,
  fetchAllCvsByPartnerThunk,
  uploadCVByEMBThunk,
} from "../../store/slices/ra/ra.slice";
import { useStyles as formStyles } from ".././../components/Form/Form.theme";
import { useParams } from "react-router-dom";
import { Dropdown, Space } from "antd";
import HireCondidate from "./HireCondidate";
import AssignAssignment from "./Assigment";
import UploadAsssment from "./UploadAsssment";
import UploadInterViewReport from "./UploadInterViewReport";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const useStyles = makeStyles({
  dropdownIconCss: {
    "& svg": {
      bottom: "24px !important",
      top: "40px !important",
    },
    "& ul": {
      marginTop: "-32px",
    },
  },
  "@media (max-width:600px)": {
    dropdownIconCss: {
      padding: "0px  !important",
    },
  },
});

const actionOptions = [
  { value: Ra_EMB_Status.EMB_SCHEDULE_INTERVIEW, label: "EMB Interview" },
  { value: Ra_EMB_Status.CLIENT_SCHEDULE_INTERVIEW, label: "Client Interview" },
  { value: Ra_EMB_Status.EMB_REJECTED, label: "Rejected" },
  { value: Ra_EMB_Status.CV_RE_UPLOADED, label: "CV Re-Uploaded" },
];

const TABLE_HEAD = [
  { id: "applicant_name", label: "Candidate Name", alignRight: false },
  { id: "skill", label: "Skills", alignRight: false },
  { id: "vendor_budget", label: "Budget", alignRight: false },
  { id: "cv_filename", label: "CV", alignRight: false },
  { id: "emb_cv_filename", label: "EMB CV", alignRight: false },
  { id: "year_of_exp", label: "Year Of Experience", alignRight: false },
  { id: "actions", label: "Actions/Status", alignRight: false },
];

function RaCvTable({ cvs }) {
  const classes = useStyles();

  const [statusSelect, setStatusSelect] = useState();
  const [reason, setReason] = useState(null);
  const [open, setOpen] = useState(false);
  const [openHire, setOpenHire] = useState(false);
  const [assignMentOpen, setAssignMentOpen] = useState(false);
  const [assignmentUploadOpen, setssignmentUploadOpen] = useState(false);
  const [interViewReportUploadOpen, setInterViewReportUploadOpen] =
    useState(false);
  const [openCV, setOpenCV] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [file, setFile] = useState(null);
  const params = useParams();
  const formClasses = formStyles();
  const [embCost, setEmbCost] = useState();

  const handleOpen = (id) => {
    setSelectedApplicantId(id);
    setOpen(true);
  };

  const handleOpenHire = (row) => {
    setSelectedApplicantId(row);
    setOpenHire(true);
  };

  const handleAssignAssigmant = (row) => {
    setSelectedApplicantId(row);
    setAssignMentOpen(true);
  };

  const handleAssignAssigmantUpload = (row) => {
    setSelectedApplicantId(row);
    setssignmentUploadOpen(true);
  };

  const handleInterViewReortUpload = (row) => {
    setSelectedApplicantId(row);
    setInterViewReportUploadOpen(true);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleInputChnage = (event) => {
    setEmbCost(event.target.value);
  };

  const handleCVModel = (id) => {
    setSelectedApplicantId(id);
    setOpenCV(true);
  };

  const handleClose = () => setOpen(false);
  const handleHireClosed = () => setOpenHire(false);
  const handleAssigmentClosed = () => setAssignMentOpen(false);
  const handleAssigmentUploadClosed = () => setssignmentUploadOpen(false);
  const handleInterViewReportUploadClosed = () =>
    setInterViewReportUploadOpen(false);
  const dispatch = useDispatch();

  const handleSelect = (e) => {
    setStatusSelect(e.target.value);
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  const getFilename = () => {
    if (!file) return null;
    const content =
      file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name;
    return file.url ? (
      <p className={classes.filename}>
        <a
          href={file.url}
          target="_blank"
          style={{ textDecoration: "none", color: "grey" }}
        >
          {content}
        </a>
      </p>
    ) : (
      <p className={classes.filename}>{content}</p>
    );
  };

  const handleStatusChange = () => {
    dispatch(
      changeStatusOfLeadThunk({
        status: statusSelect,
        type:'rejected',
        remarks: reason,
        lead_id: params.leadId,
        applicant_id: selectedApplicantId,
      })
    )
      .unwrap()
      .then(() => {
        setOpen(false);
        dispatch(fetchAllCvsByPartnerThunk(params.leadId));
      });
  };

  const handleCVUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("emb_cv_file", file);
      formData.append("emb_cv_filename", file.name);
      formData.append("applicant_emb_cost", embCost);
      dispatch(
        uploadCVByEMBThunk({
          id: selectedApplicantId,
          formData: formData,
          leadId: params.leadId,
        })
      )
        .unwrap()
        .then(() => {
          setOpenCV(false);
          dispatch(fetchAllCvsByPartnerThunk(params.leadId));
        });
    }
  };

  const handleMoveToClient = (id) => {
    // const allow = confirm('Are you sure you want to move to client?');
    if (true) {
      dispatch(
        changeStatusOfLeadThunk({
          status: "move_to_client",
          // remarks: "Moved to Client",
          lead_id: params.leadId,
          applicant_id: id,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(fetchAllCvsByPartnerThunk(params.leadId));
        });
    }
  };

  // const dropdownItems = [
  //   {
  //     key: "1",
  //     label: <a onClick={() => handleCVModel(id)}>Upload CV</a>,
  //   },
  //   {
  //     key: "2",
  //     label: <a>Move To HR</a>,
  //   },
  //   {
  //     key: "3",
  //     label: <a>Move to Interview.io</a>,
  //   },
  //   {
  //     key: "4",
  //     label: <a>Move to Client</a>,
  //   },
  //   {
  //     key: "5",
  //     label: <a onClick={() => handleOpen(id)}>Reject</a>,
  //   },
  // ];

  console.log("cvs", cvs);

  return (
    <Card>
      <Scrollbar>
        <TableContainer
          sx={{ minWidth: 800, padding: "20px", overflowX: "auto" }}
        >
          <Table>
            <ListHead
              rowCount={cvs.length}
              styles={{ backgroundColor: "rgb(243, 246, 248)" }}
              headLabel={TABLE_HEAD}
            />
            <TableBody>
              {cvs.map((row) => {
                const {
                  applicant_name,
                  cv_filename,
                  cv_url,
                  emb_applicant_status,
                  skill_name,
                  vendor_budget,
                  year_of_exp,
                  emb_cv_filename,
                  id,
                  client_applicant_status,
                  client_rejection_reason,
                  emb_cv_url,
                  next_round,
                } = row;

                const items = [
                  {
                    disabled: emb_applicant_status === "move_to_client",
                    key: "1",
                    label: (
                      <span onClick={() => handleCVModel(id)}>Upload CV</span>
                    ),
                  },
                  {
                    disabled: emb_applicant_status === "move_to_client",
                    key: "2",
                    label: <span>Move To HR</span>,
                  },
                  {
                    disabled: emb_applicant_status === "move_to_client",
                    key: "3",
                    label: <span>Move to Interview.io</span>,
                  },
                  {
                    disabled: emb_applicant_status === "move_to_client",
                    key: "4",
                    label: (
                      <span onClick={() => handleMoveToClient(id)}>
                        Move to Client
                      </span>
                    ),
                  },
                  {
                    disabled:
                      emb_applicant_status === "move_to_client"
                        ? next_round
                          ? client_applicant_status === "pending"
                            ? false
                            : true
                          : false
                        : false,
                    key: "5",
                    label: (
                      <span onClick={() => handleAssignAssigmant(row)}>
                        Upload Assigment
                      </span>
                    ),
                  },
                  {
                    key: "6",
                    label: <span onClick={() => handleOpen(id)}>Reject</span>,
                  },
                  {
                    disabled: emb_applicant_status !== "move_to_client",
                    key: "7",
                    label: (
                      <span onClick={() => handleOpenHire(row)}>Hire</span>
                    ),
                  },
                  {
                    disabled:
                      client_applicant_status === "assessment_completed"
                        ? false
                        : true,
                    key: "8",
                    label: (
                      <span onClick={() => handleAssignAssigmantUpload(row)}>
                        Submit Assesment
                      </span>
                    ),
                  },
                  {
                    key: "6",
                    label: (
                      <span onClick={() => handleInterViewReortUpload(row)}>
                        Upload Interview Report
                      </span>
                    ),
                  },
                ];

                return (
                  <TableRow hover key={id} tabIndex={-1} role="checkbox">
                    <TableCell align="left">
                      {capitalCase(applicant_name)}
                    </TableCell>
                    <TableCell align="left">
                      {capitalCase(skill_name)}
                    </TableCell>
                    <TableCell align="left">
                      {capitalCase(vendor_budget)}
                    </TableCell>
                    <TableCell noWrap align="left">
                      {cv_url ? (
                        <a target="_blank" href={cv_url}>
                          {capitalCase(cv_filename)}
                        </a>
                      ) : (
                        <>-</>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "200px" }} align="left">
                      {emb_cv_url ? (
                        <a href={emb_cv_url} target="_blank">
                          {capitalCase(emb_cv_filename)}
                        </a>
                      ) : (
                        <>-</>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {capitalCase(year_of_exp)}
                    </TableCell>
                    <TableCell
                      sx={{ display: "flex", gap: "5px" }}
                      align="left"
                    >
                      {/* {emb_applicant_status === "cv_uploaded" ? (
                        <Space direction="vertical">
                          <Space wrap>
                            <Dropdown menu={{ items }} placement="bottom">
                              <Button>-- Select --</Button>
                            </Dropdown>
                          </Space>
                        </Space>
                      ) : (
                        <strong style={{ color: "gray" }}>
                          {capitalCase(
                            emb_applicant_status.split("_").join(" ")
                          )}
                        </strong>
                      )} */}
                      {client_applicant_status === "hired" ||
                      client_applicant_status === "rejected" ? (
                        <strong
                          style={{
                            color:
                              client_applicant_status === "hired"
                                ? "green"
                                : "red",
                          }}
                        >
                          {capitalCase(client_applicant_status)}
                        </strong>
                      ) : (
                        <Space direction="vertical">
                          <Space wrap>
                            <Dropdown menu={{ items }} placement="bottom">
                              <Button>-- Select --</Button>
                            </Dropdown>
                          </Space>
                        </Space>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <label className={formClasses.formLabel}>Remarks</label>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              flexDirection: "column",
            }}
            className={classes.dropdownIconCss}
          >
            <Element
              eletype="select"
              label="Select status"
              value={statusSelect}
              options={actionOptions}
              inputProps={{
                type: "text",
                onChange: handleSelect,
              }}
            />
            <Element
              label={"Write a reason"}
              eletype="textarea"
              value={reason}
              inputProps={{
                type: "text",
                onChange: handleReason,
              }}
            />
            <Button
              size="large"
              type="submit"
              variant="contained"
              sx={{ marginLeft: "5px" }}
              onClick={handleStatusChange}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openCV}
        onClose={() => setOpenCV(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              flexDirection: "column",
            }}
            className={classes.dropdownIconCss}
          >
            <label className={formClasses.formLabel}>Upload EMB CV</label>
            <div className="file-upload">
              <div className="file-select">
                <div className="file-select-button" id="fileName">
                  Upload
                </div>
                <div className="file-select-name" id="noFile">
                  Browse File...
                </div>
                <input
                  type="file"
                  name="chooseFile"
                  className={formClasses.formControl}
                  onChange={handleFileChange}
                />
              </div>
            </div>
            {file?.name && (
              <p className={formClasses.filename} style={{ margin: 0 }}>
                {getFilename()}
              </p>
            )}
            <Element
              type="number"
              name="applicantembcost"
              label={"Applican EMB cost"}
              value={embCost}
              inputProps={{
                name: "applicantembcost",
                onChange: handleInputChnage,
                placeholder: "Applican EMB cost",
              }}
              eletype={inputType.input}
            />
            <Button
              size="large"
              type="submit"
              variant="contained"
              sx={{ marginLeft: "5px" }}
              onClick={handleCVUpload}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* assignment */}
      <Modal
        open={openHire}
        onClose={handleHireClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <HireCondidate
          applicantId={selectedApplicantId}
          onClose={handleHireClosed}
        />
      </Modal>
      <Modal
        open={assignMentOpen}
        onClose={handleAssigmentClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AssignAssignment
          applicantId={selectedApplicantId}
          onClose={handleAssigmentClosed}
        />
      </Modal>

      <Modal
        open={assignmentUploadOpen}
        onClose={handleAssigmentUploadClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UploadAsssment
          applicantId={selectedApplicantId}
          onClose={handleAssigmentUploadClosed}
        />
      </Modal>
      <Modal
        open={interViewReportUploadOpen}
        onClose={handleInterViewReportUploadClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UploadInterViewReport
          applicantId={selectedApplicantId}
          onClose={handleInterViewReportUploadClosed}
        />
      </Modal>
    </Card>
  );
}

export default RaCvTable;
